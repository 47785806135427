import React, { useState, useRef, useEffect } from "react";
import style from "./ItemUpdate.module.css";
import {
  MenuItem,
  TextField,
  FormControl,
  Select,
  Checkbox,
  InputLabel,
  Alert,
  Snackbar,
  InputAdornment,
  Button,
  Modal,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FaImage } from "react-icons/fa6";
import { IoMdCloudUpload } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { baseUrl } from "../Url";
import { useOutletContext } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import QrScanner from "react-qr-scanner";
import { QRCodeCanvas } from "qrcode.react";
import { TbLayersSubtract } from "react-icons/tb";

function ItemUpdate() {
  const location = useLocation();
  const { selectedNewHub } = useOutletContext();
  const navigate = useNavigate();
  const typeRef = useRef(null);
  const token = JSON.parse(localStorage.getItem("access-token"));
  const qrCodeCanvasRef = useRef();

  const state = location.state;
  const [editId, setEditId] = useState(state.editId);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedType, setSelectedType] = useState({});
  const [showType, setShowType] = useState({});
  const [attributeSections, setAttributeSections] = useState([]);
  const [variantSections, setVariantSections] = useState([]);
  const [itemImages, setItemImages] = useState([]);
  const [variantExist, setVariantExist] = useState(null);
  const [manufacturingItem, setManufacturingItem] = useState(false);
  const [fixedAsset, setFixedAsset] = useState(false);
  const [transferableAsset, setTransferableAsset] = useState(false);
  const [maintainStock, setMaintainStock] = useState(false);
  const [itemName, setItemName] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [barCode, setBarCode] = useState("");
  const [itemType, setItemType] = useState("");
  const [makingCost, setMakingCost] = useState("");
  const [inventoryCost, setInventoryCost] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [saleDiscountPercentage, setSaleDiscountPercentage] = useState("");
  const [SaleTaxPercentage, setSaleTaxPercentage] = useState("");
  const [purchaseTaxPercentage, setPurchasePercentage] = useState("");
  const [status, setStatus] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [attributeData, setAttributeData] = useState([]);
  const [savedAttributes, setSavedAttributes] = useState([]);
  const [savedVariation, setSavedVariation] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [taxType, setTaxType] = useState(null);
  const [scannerVisible, setScannerVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [itemCreateDisabled, setItemCreate] = useState(false);
  const [changedAttributeList, setChangedAttributeList] = useState([]);
  const [discardedAttibute, setDiscardedAttibute] = useState([]);
  const [discardedVariant, setDiscardedVariant] = useState([]);
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200, // Set the maximum height you want
      },
    },
  };

  const handleScan = (data) => {
    if (data) {
      setBarCode(data.text); // Update the barcode input with the scanned data
      setScannerVisible(false); // Optionally hide the scanner after a successful scan
    }
  };

  const validateNumber = (value) => {
    const regex = /^(0|[1-9]\d*)(\.\d{0,2})?$/;
    return value === "" || regex.test(value);
  };

  // Handle any errors from the QR code scanner
  const handleError = (err) => {
    console.error(err);
  };

  const handlePrint = () => {
    const canvas = qrCodeCanvasRef.current.querySelector("canvas"); // Access the QR code canvas
    if (canvas) {
      const imageDataUrl = canvas.toDataURL(); // Convert the canvas to a data URL
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
          <title>Print QR Code</title>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh; /* Full height for vertical centering */
              margin: 0;
            }
            img {
              max-width: 100%; /* Make sure the image scales */
              height: auto;
            }
          </style>
          </head>
          <body>
            <img id="qrCodeImage" src="${imageDataUrl}" alt="QR Code" />
            <script>
              window.onload = function() {
                window.print();
                window.close();
              }
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTypeSelect = (attributeID, type) => {
    setSelectedType((prevSelectedType) => {
      const typesForAttribute = prevSelectedType[attributeID] || [];
      const isChecked = typesForAttribute.find(
        (selected) => selected.type_code === type.type_code
      );
      if (!isChecked) {
        return {
          ...prevSelectedType,
          [attributeID]: [...typesForAttribute, type],
        };
      } else {
        return prevSelectedType;
      }
    });
  };
  const handleRemoveType = (attributeID, typeCodeToRemove) => {
    setSelectedType((prevSelectedType) => {
      const updatedTypesForAttribute = prevSelectedType[attributeID].filter(
        (type) => type.type_code !== typeCodeToRemove
      );
      return {
        ...prevSelectedType,
        [attributeID]: updatedTypesForAttribute,
      };
    });
  };

  const handleVarientonAttributeRemoval = (attributeID, type_id = null) => {
    const updatedVariantSections = variantSections.filter((variant) => {
      // Remove variants if `attributes` is an empty object
      if (Object.keys(variant.attributes).length === 0) {
        return false;
      }
      // If type_id is provided, remove variants where the attribute matches both attributeID and type_id
      if (type_id) {
        return !(
          variant.attributes && variant.attributes[attributeID] === type_id
        );
      }
      // If no type_id, remove variants where the attribute exists (regardless of type_id)
      return !(variant.attributes && attributeID in variant.attributes);
    });

    const updatedSavedVariation = savedVariation.filter((variant) => {
      if (Object.keys(variant.attributes).length === 0) {
        return false;
      }
      if (type_id) {
        return !(
          variant.attributes && variant.attributes[attributeID] === type_id
        );
      }
      return !(variant.attributes && attributeID in variant.attributes);
    });
    
    if (
      updatedVariantSections.length < variantSections.length ||
      updatedSavedVariation.length < savedVariation.length
    ) {
      const removedItems = savedVariation.filter(
        savedItem => !updatedSavedVariation.some(
          updatedItem => updatedItem.variantID === savedItem.variantID
        )
      );
      removedItems.forEach( item=>{
        if(isAlreadySavedVarient(item.variantID)){
          setDiscardedVariant((prev) => [...prev, item.variantID]);
        }
      })
      setVariantSections(updatedVariantSections);
      setSavedVariation(updatedSavedVariation);
      setErrorMessage("Created Variant with deleted attribute will be removed");
      setValidationFail(true);
    }
  };

  const  isAlreadySavedVarient = (variantID) => {
    const found = savedVariation.some(
      item => item.variantID === variantID && item.data_source === "saved"
    );
    return found;
  }

  const generateUniqueId = () => {
    const timestamp = new Date().getTime(); // Get current timestamp
    const randomId = Math.random().toString(36).substring(2, 15); // Generate random number
    return `${timestamp}-${randomId}`; // Combine timestamp and random number
  };

  const handleAddAttribute = async () => {
    if (selectedAttribute === "custom product attribute") {
      const newId = generateUniqueId();
      // Add custom attribute section
      setAttributeSections((prevSections) => [
        ...prevSections,
        {
          type: "custom",
          name: "",
          values: "",
          isExpanded: true,
          attributeID: newId,
        },
      ]);
    } else {
      const selectedAttributeObj = attributeData.find(
        (attr) => attr.attribute_code === selectedAttribute
      );
      // Check if the attribute already exists in the attributeSections
      const attributeExists = attributeSections.some(
        (section) => section.attributeID === selectedAttributeObj.attribute_id
      );
      if (!attributeExists) {
        const attributeTypes = await attrTypeList();
        setAttributeSections((prevSections) => [
          ...prevSections,
          {
            type: "existing",
            name: selectedAttributeObj.attribute_name,
            values: "",
            isExpanded: true,
            attributeID: selectedAttributeObj.attribute_id,
            attributeType: attributeTypes,
          },
        ]);
        if (savedVariation.length > 0) {
          setVariantSections([]);
          setSavedVariation([]);
          setErrorMessage("Created Varient will be removed");
          setValidationFail(true);
        }
      } else {
        setErrorMessage("Attribute already exists");
        setValidationFail(true);
      }
    }
  };

  const handleDeleteAttribute = (attributeID) => {
    const checkIfFirstIsSaved = (key) => {
      // Find the object containing the key
      const keyObject = savedAttributes.find(item => key in item);
      
      if (keyObject) {
        const arrayOfObjects = keyObject[key];
        // Check if the first object has data_source as "saved"
        return arrayOfObjects.length > 0 && arrayOfObjects[0].data_source === "saved";
      }
      
      return false; // Return false if key not found
    }

    if(checkIfFirstIsSaved(attributeID)){
      setDiscardedAttibute((prev) => [...prev, attributeID]);
    }
   
    const updatedList = changedAttributeList.filter((id) => id !== attributeID);
    setChangedAttributeList(updatedList);
    // Remove from attributeSections
    setAttributeSections((prevSections) =>
      prevSections.filter((section) => section.attributeID !== attributeID)
    );
    // Remove from selectedType
    setSelectedType((prevSelectedType) => {
      const { [attributeID]: _, ...remainingSelectedTypes } = prevSelectedType;
      return remainingSelectedTypes;
    });
    // Remove from savedAttributes
    setSavedAttributes((prevSavedAttributes) =>
      prevSavedAttributes.filter(
        (obj) => !Object.keys(obj).includes(attributeID)
      )
    );

    handleVarientonAttributeRemoval(attributeID);
  };

  const handleExpand = (attributeID) => {
    setAttributeSections((prevSections) =>
      prevSections.map((section) =>
        section.attributeID === attributeID
          ? { ...section, isExpanded: !section.isExpanded }
          : section
      )
    );
  };

  const handleSaveAttributes = (attributeID) => {
    const typesForAttribute = selectedType[attributeID] || [];
    const attributeObject = { [attributeID]: typesForAttribute };
    const updatedList = changedAttributeList.filter((id) => id !== attributeID);
    setChangedAttributeList(updatedList);
    if (typesForAttribute.length > 0) {
      setSavedAttributes((prevSavedAttributes) => {
        const newSavedAttributes = Array.isArray(prevSavedAttributes)
          ? [...prevSavedAttributes]
          : [];
        // Find the index of the existing attribute with the same attributeID
        const existingIndex = newSavedAttributes.findIndex(
          (attr) => Object.keys(attr)[0] === attributeID
        );
        if (existingIndex !== -1) {
          // If found, replace the existing attribute with the new one
          const existingAttributeList =
            newSavedAttributes[existingIndex][attributeID];
          const newAttributeList = attributeObject[attributeID];
          const removedAttribute = existingAttributeList.filter(
            (item) => !newAttributeList.includes(item)
          );
          removedAttribute.forEach((attr) => {
            handleVarientonAttributeRemoval(attributeID, attr.type_id);
          });
          newSavedAttributes[existingIndex] = attributeObject;
        } else {
          // If not found, add the new attribute
          newSavedAttributes.push(attributeObject);
        }
        return newSavedAttributes;
      });
      handleExpand(attributeID);
    } else {
      setErrorMessage("Please add atleast one type");
      setValidationFail(true);
      const foundObject = savedAttributes.find(
        (attr) => Object.keys(attr)[0] === attributeID
      );
      if (foundObject) {
        const attrTypeList = foundObject[attributeID];
        attrTypeList.forEach((item) => {
          handleTypeSelect(attributeID, item);
        });
      }
    }
  };

  const handleVarient = () => {
    if (savedAttributes.length > 0) {
      const newId = generateUniqueId();
      const selectedAttributeObj = attributeData.find(
        (attr) => attr.attribute_code === selectedAttribute
      );
      const selectedAttributeName = selectedAttributeObj
        ? selectedAttributeObj.attribute_name
        : "";
      setVariantSections((prevVariantSections) => [
        ...prevVariantSections,
        {
          type: "custom",
          name: selectedAttributeName,
          isExpanded: true,
          variantID: newId,
          image: [],
          regular_price: "",
          sale_price: "",
          stock_status: "",
          description: "",
          attributes: {}, // Initialize the attributes field
        },
      ]);
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      setErrorMessage("Create Attribute before proceeding with Varient");
      setValidationFail(true);
    }
  };

  const renderVariantDropdowns = (variantID) => {
    return savedAttributes.map((attribute, index) => {
      const attributeID = Object.keys(attribute)[0];
      const attributeOptions = attribute[attributeID];
      return (
        <TextField
          key={attributeID}
          select
          label={`Select from Attribute ${index + 1}`}
          fullWidth
          margin="normal"
          sx={{ marginRight: "0.125rem", minWidth: "150px", maxWidth: "200px" }}
          value={
            variantSections.find((v) => v.variantID === variantID)?.attributes[
              attributeID
            ] || ""
          }
          onChange={(e) => {
            setVariantSections((prevVariantSections) => {
              return prevVariantSections.map((variant) => {
                if (variant.variantID === variantID) {
                  const newAttributes = {
                    ...variant.attributes,
                    [attributeID]: e.target.value,
                  };
                  return { ...variant, attributes: newAttributes };
                }
                return variant;
              });
            });
          }}
        >
          {attributeOptions.map((option) => (
            <MenuItem key={option.type_id} value={option.type_id}>
              {option.type_name}
            </MenuItem>
          ))}
        </TextField>
      );
    });
  };

  const handleVariantChange = (variantID, field, value) => {
    setVariantSections((prevSections) =>
      prevSections.map((section) =>
        section.variantID === variantID
          ? { ...section, [field]: value }
          : section
      )
    );
  };

  const handleVariantImage = (variant_index) => {
    document.getElementById("variantImageUpload_" + variant_index).click();
  };

  const handleVariantImageUpload = async (e, variantID) => {
    const files = e.target.files;
    const uploadedImages = [];
    for (let file of files) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await fetch(`${baseUrl}/manage/media`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          const imageUrl = responseData.data[0].image_url;
          uploadedImages.push(imageUrl);
        } else {
          console.error("Failed to upload image", response);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    if (uploadedImages.length > 0) {
      setVariantSections((prevSections) =>
        prevSections.map((section) =>
          section.variantID === variantID
            ? {
                ...section,
                image: (section.image || []).concat(uploadedImages),
              }
            : section
        )
      );
    }
  };

  const handleDeleteVariantImage = (variantID, imageIndex) => {
    setVariantSections((prevSections) =>
      prevSections.map((section) =>
        section.variantID === variantID
          ? {
              ...section,
              image: section.image.filter((_, index) => index !== imageIndex),
            }
          : section
      )
    );
  };

  const areAttributesEqual = (attr1, attr2) => {
    const keys1 = Object.keys(attr1);
    const keys2 = Object.keys(attr2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (attr1[key] !== attr2[key]) return false;
    }
    return true;
  };

  const handleSaveVariant = (variantId) => {
    // Fetch the object from variantSections with matching variantID
    const variantToSave = variantSections.find(
      (section) => section.variantID === variantId
    );

    if (Object.keys(variantToSave.attributes).length === 0) {
      setErrorMessage("Attributes cannot be empty.");
      setValidationFail(true);
      return;
    }

    // Check and update regular_price and sale_price
    if (variantToSave.regular_price <= 0) {
      variantToSave.regular_price = 0;
    }
    if (variantToSave.sale_price <= 0) {
      variantToSave.sale_price = 0;
    }
    // Destructure the object to exclude type and isExpanded
    const { type, isExpanded, ...objectToSave } = variantToSave;

    setSavedVariation((prevSavedVariation) => {
      // CASE 1: Check if variantID and attributes both match in savedVariation
      const exactMatch = prevSavedVariation.find(
        (savedVar) =>
          savedVar.variantID === objectToSave.variantID &&
          areAttributesEqual(savedVar.attributes, objectToSave.attributes)
      );

      if (exactMatch) {
        // Update savedVariation
        const updatedVariations = prevSavedVariation.map((savedVar) =>
          savedVar.variantID === objectToSave.variantID
            ? { ...savedVar, ...objectToSave } // Update with new object data
            : savedVar
        );
        handleVarientExpand(variantId);
        setValidationSuccess(true);
        setSuccessMessage("Variant updated successfully!");
        return updatedVariations;
      }

      // CASE 2: Check if only attributes match in savedVariation
      const attributeMatch = prevSavedVariation.find((savedVar) =>
        areAttributesEqual(savedVar.attributes, objectToSave.attributes)
      );

      if (attributeMatch) {
        setErrorMessage("A variant with the same attributes already exists!");
        setValidationFail(true);
        return prevSavedVariation; // No update
      }

      // CASE 3: Neither variantID nor attributes match
      const newVariations = [...prevSavedVariation, objectToSave];
      handleVarientExpand(variantId);
      setValidationSuccess(true);
      setSuccessMessage("Variant added successfully!");
      return newVariations; // Add new object
    });
  };

  const handleVarientExpand = (variantID) => {
    setVariantSections((prevState) =>
      prevState.map((variSection, idx) =>
        variSection.variantID === variantID
          ? { ...variSection, isExpanded: !variSection.isExpanded }
          : variSection
      )
    );
  };

  const handleVarientDelete = (variantID) => {
    if(isAlreadySavedVarient(variantID)){
      setDiscardedVariant((prev) => [...prev, variantID]);
    }
    setVariantSections((prevSections) =>
      prevSections.filter((section) => section.variantID !== variantID)
    );
    setSavedVariation((prevSections) =>
      prevSections.filter((section) => section.variantID !== variantID)
    );
  };

  const handleImageUpload = () => {
    document.getElementById("stockItemImage").click();
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const uploadedImages = [];
    for (let file of files) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await fetch(`${baseUrl}/manage/media`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          const imageUrl = responseData.data[0].image_url;
          uploadedImages.push(imageUrl);
        } else {
          console.error("Failed to upload image", response);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
    if (uploadedImages.length > 0) {
      setItemImages((prevImages) => [...prevImages, ...uploadedImages]);
    }
    e.target.value = "";
  };

  const handleReplaceImage = async (index, file) => {
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fetch(`${baseUrl}/manage/media`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const responseData = await response.json();
        const imageUrl = responseData.data[0].image_url;
        setItemImages((prevImages) =>
          prevImages.map((img, i) => (i === index ? imageUrl : img))
        );
      } else {
        console.error("Failed to replace image", response);
      }
    } catch (error) {
      console.error("Error replacing image:", error);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = itemImages.filter((_, i) => i !== index);
    setItemImages(updatedImages);
  };

  const handleCheckboxChange = (categoryID) => {
    const newSelectedcategory = selectedCategory.includes(categoryID)
      ? selectedCategory.filter((id) => id !== categoryID)
      : [...selectedCategory, categoryID];
    setSelectedCategory(newSelectedcategory);
  };

  
  useEffect(() => {
    categoryList();
  }, [selectedNewHub,editId]);

  useEffect(() => {
    setIsDisabled(!barCode.trim());
  }, [barCode]);

  useEffect(() => {}, [variantSections]);

  useEffect(() => {
  }, [savedVariation]);
  useEffect(() => {
  }, [savedAttributes]);
  useEffect(() => {}, [selectedType]);

  useEffect(() => {
    // Check if data is edited
    const editselectedType = Object.entries(selectedType).map(
      ([key, value]) => ({ [key]: value })
    );
    const changedAttrList = findChangedKeys(savedAttributes, editselectedType);
    setChangedAttributeList(changedAttrList);
  }, [selectedType, savedAttributes]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (typeRef.current && !typeRef.current.contains(event.target)) {
        setShowType(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const findChangedKeys = (savedAttributes, editselectedType) => {
    const changedKeys = [];
    editselectedType.forEach((obj2, index) => {
      const obj1 = savedAttributes[index];

      if (obj1) {
        // Compare keys in both objects
        Object.keys(obj2).forEach((key) => {
          if (!obj1[key]) {
            // Key is new
            changedKeys.push(key);
          } else if (obj1[key].length !== obj2[key].length) {
            // Key exists but array length has changed
            changedKeys.push(key);
          } else {
            // Compare values if array length is the same
            const arraysAreEqual = obj1[key].every(
              (value, idx) => value === obj2[key][idx]
            );
            if (!arraysAreEqual) {
              // If values don't match, add the key to changedKeys
              changedKeys.push(key);
            }
          }
        });
      } else {
        // If there's no corresponding object in savedAttributes, consider all keys in obj2 as new
        changedKeys.push(...Object.keys(obj2));
      }
    });

    return changedKeys;
  };

  const createValidation = () => {
    if (!itemName || itemName.trim().length === 0) {
      setErrorMessage("Item name is required");
      return false;
    }
    if (!shortDesc || shortDesc.trim().length === 0) {
      setErrorMessage("Short description is required");
      return false;
    }
    if (selectedCategory.length === 0 && !fixedAsset && !transferableAsset) {
      setErrorMessage("Please select a category");
      return false;
    }
    if (!taxType && !fixedAsset && !transferableAsset) {
      setErrorMessage("Please select tax type");
      return false;
    }
    if (variantExist === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Please specify variant option");
      return false;
    }
    if (!itemType) {
      setErrorMessage("Please select an item type");
      return false;
    }
    if (makingCost === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Making cost required");
      return false;
    }
    if (inventoryCost === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Inventory cost required");
      return false;
    }
    if (purchasePrice === "") {
      setErrorMessage("Purchase price required");
      return false;
    }
    if (salePrice === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Sale price required");
      return false;
    }
    if (saleDiscountPercentage === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Sale discount percentage required");
      return false;
    }
    if (purchaseTaxPercentage === "") {
      setErrorMessage("Purchase tax percentage required");
      return false;
    }
    if (SaleTaxPercentage === "" && !fixedAsset && !transferableAsset) {
      setErrorMessage("Sale tax percentage required");
      return false;
    }
    if (!status) {
      setErrorMessage("Status is required");
      return false;
    }
    if (!itemImages || itemImages.length === 0) {
      setErrorMessage("Please upload at least one image");
      return false;
    }
    if (!barCode || barCode.length === 0) {
      setErrorMessage("QR Code required");
      return false;
    }
    if (
      variantExist &&
      (savedAttributes.length === 0 || savedVariation.length === 0)
    ) {
      setErrorMessage("Attributes and Varient Required");
      return false;
    }
    if (
      !(fixedAsset || transferableAsset || manufacturingItem || maintainStock)
    ) {
      setErrorMessage("Stock/Asset Type Required");
      return false;
    }
    return true;
  };

  async function categoryList() {
    const reqData = {
      org_id: selectedNewHub,
    };
    console.log(reqData, "reqdataaa");
    await fetch(`${baseUrl}/hub/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCategoryData(data.data);
          getItemDetails();
        }
      });
  }

  async function listAttribute() {
    await fetch(`${baseUrl}/attribute/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setAttributeData(data.data);
        }
      });
  }

  async function attrTypeListUpdate(selectedAttri) {
    const reqData = {
      attribute_code: selectedAttri,
      attribute_id: "",
    };
    const response = await fetch(`${baseUrl}/attribute/type/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData.data;
    } else {
      console.error("Failed to upload image", response);
    }
  }

  async function attrTypeList() {
    const reqData = {
      attribute_code: selectedAttribute,
      attribute_id: "",
    };
    const response = await fetch(`${baseUrl}/attribute/type/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData.data;
    } else {
      console.error("Failed to upload image", response);
    }
  }

  async function getItemDetails() {
    await fetch(`${baseUrl}/stock/item/detail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        itemID: editId
      })
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'edit details');
        if (data.status === true) {
          setItemName(data.data[0].ItemName)
          setShortDesc(data.data[0].ShortDescription)
          setLongDesc(data.data[0].LongDescription)
          setItemType(data.data[0].ItemType)
          setVariantExist(data.data[0].IsVariantsExist)
          setMakingCost(data.data[0].MakingCost)
          setInventoryCost(data.data[0].InventoryCost)
          setPurchasePrice(data.data[0].PurchasePrice)
          setSalePrice(data.data[0].SalePrice)
          setSaleDiscountPercentage(data.data[0].SaleDiscountPercentage)
          setPurchasePercentage(data.data[0].PurchaseTaxPercentage)
          setSaleTaxPercentage(data.data[0].SaleTaxPercentage)
          setStatus(data.data[0].Status)
          setHsnCode(data.data[0].hsn_code)
          setBarCode(data.data[0].bar_code)
          setManufacturingItem(data.data[0].is_manufacturing_item)
          console.log("is_manufacturing_item",data.data[0].is_manufacturing_item)
          setFixedAsset(data.data[0].is_fixed_asset)
          setTransferableAsset(data.data[0].is_transferable_asset)
          setMaintainStock(data.data[0].maintain_stock)
          const newImages = data.data[0].media.map(img => img.MediaThumbnail);
          setItemImages(newImages);
          data.data[0].category_details.forEach(catId => {
            handleCheckboxChange(catId);
          });
          const tax_type = data.data[0].product_tax_type === true ? "true" : "false"
          setTaxType(tax_type)

          const attributList = data.data[0].attribute_data.flatMap(item => item.attribute_data?.attribute || []);
          const attributListAllList = data.data[0].attribute_data.flatMap(item => item.attribute_data?.all_types || []);
          attributList.forEach(attr => {
            const matchingObjects = attributListAllList.filter(
              item => item.attribute_code === attr.attribute_code
            );
            setAttributeSections((prevSections) => [
              ...prevSections,
              {
                type: "existing",
                name: attr.attribute_name,
                values: "",
                isExpanded: false,
                attributeID: attr.attribute_id,
                attributeType: matchingObjects,
              },
            ]);
            
          })
          
          const savedAttribute = data.data[0].attribute_data.flatMap(item => item.attribute_data?.attribute_type || []);
          let newVar = savedAttribute.map((item) => ({
            ...item,
            data_source: "saved"
          }))

          setSavedAttributes(transformArrayToSaveAttrbutes(newVar));
          setSelectedType(transformArrayToObjectSelectedType(newVar));

         

          let varList = data.data[0].variant;
          varList.forEach(variant => {
            const attributes = variant.attribute_type.reduce((acc, item) => {
                acc[item.attribute_id] = item.type_id;
                return acc;
            }, {});
           
            setVariantSections((prevVariantSections) => [
            ...prevVariantSections,
            {
              type: "custom",
              name: variant.variant_name,
              isExpanded: false,
              variantID: variant.variant_id,
              image: variant.image_file,
              regular_price: variant.regular_price,
              sale_price: variant.sale_price,
              stock_status: variant.stock_status,
              description: variant.description,
              attributes: attributes, 
            },
          ]);
          setSavedVariation((prevVariantSections) => [
            ...prevVariantSections,
            {
              name: variant.variant_name,
              variantID: variant.variant_id,
              image: variant.image_file,
              regular_price: variant.regular_price,
              sale_price: variant.sale_price,
              stock_status: variant.stock_status,
              description: variant.description,
              data_source: "saved",
              attributes: attributes, 
            },
          ]);
        });
         
        }
      })
  }

 


  const transformArrayToObjectSelectedType = (data) => {
    return data.reduce((acc, item) => {
        const key = item.attribute_id;
        // If the key doesn't exist in the accumulator, initialize it as an array
        if (!acc[key]) {
            acc[key] = [];
        }
        // Push the current item into the corresponding array
        acc[key].push(item);
        return acc;
    }, {});
  };

  const transformArrayToSaveAttrbutes = (data) => {
    // Use a reducer to group objects by attribute_id
    const result = data.reduce((acc, item) => {
        const key = item.attribute_id;
        // If the key doesn't exist in the accumulator, create an array for it
        if (!acc[key]) {
            acc[key] = [];
        }
        // Push the current item into the corresponding array
        acc[key].push(item);
        return acc;
    }, {});
    
    // Convert the grouped object into an array of objects with the desired format
    return Object.keys(result).map(key => ({ [key]: result[key] }));
};

  async function menuItemsUpdate() {
    setItemCreate(true);
    let itemData = {
      org_id: selectedNewHub,
      itemID: editId,
      ItemName: itemName,
      category_id: selectedCategory,
      ShortDescription: shortDesc,
      LongDescription: longDesc,
      ItemType: itemType,
      IsVariantsExist: variantExist,
      MakingCost: parseFloat(makingCost),
      InventoryCost: parseFloat(inventoryCost),
      PurchasePrice: parseFloat(purchasePrice),
      SalePrice: parseFloat(salePrice),
      SaleDiscountPercentage: parseFloat(saleDiscountPercentage),
      PurchaseTaxPercentage: parseFloat(purchaseTaxPercentage),
      SaleTaxPercentage: parseFloat(SaleTaxPercentage),
      Status: status,
      image: itemImages,
      attribute_item: variantExist === true ? savedAttributes : [],
      variant_item: variantExist === true ? savedVariation : [],
      product_tax_type: taxType === "true" ? true : false,
      hsn_code: hsnCode,
      bar_code: barCode,
      is_manufacturing_item: manufacturingItem,
      is_fixed_asset: fixedAsset,
      is_transferable_asset: transferableAsset,
      maintain_stock: maintainStock,
      discarded_attribute:discardedAttibute,
      discarded_variant:discardedVariant
    };
    console.log(itemData, "itemData");
    if (!createValidation()) {
      setValidationFail(true);
      setItemCreate(false);
      return;
    } else {
      await fetch(`${baseUrl}/Item/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(itemData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "createdData");
          if (data.status === true) {
            navigate("/stockitems");
          } else {
            setErrorMessage("Some Error Occured");
            setValidationFail(true);
            window.location.href = "/stockitems";
          }
        });
    }
  }


  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationSuccess(false);
        }}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Stock Items Updation</p>
        </div>
        <div className={style.buttonSection}>
          <button
            disabled={itemCreateDisabled}
            onClick={() => {
              menuItemsUpdate();
            }}
          >
            Update Stock
          </button>
        </div>
      </div>
      <div className={style.tabSection}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#59293a" } }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            label="StockItem"
            sx={{
              "&.Mui-selected": {
                color: "#59293a",
                backgroundColor: "",
              },
              fontSize: "1.1rem",
              color: "#000",
              backgroundColor: "",
              margin: "0 .5rem",
              borderRadius: "10px",
              // textTransform: "capitalize",
            }}
          />
          {variantExist === true && (
            <Tab
              label=" Attributes"
              sx={{
                "&.Mui-selected": {
                  color: "#59293a",
                  backgroundColor: "",
                },
                fontSize: "1.1rem",
                color: "#000",
                backgroundColor: "",
                margin: "0 .5rem",
                borderRadius: "10px",
                // textTransform: "capitalize",
              }}
              onClick={() => {
                listAttribute();
              }}
            />
          )}
          {variantExist === true && (
            <Tab
              label=" Varients"
              sx={{
                "&.Mui-selected": {
                  color: "#59293a",
                  backgroundColor: "",
                },
                fontSize: "1.1rem",
                color: "#000",
                backgroundColor: "",
                margin: "0 .5rem",
                borderRadius: "10px",
                // textTransform: "capitalize",
              }}
            />
          )}
        </Tabs>
      </div>
      <div className={style.typography}>
        <Typography>
          {selectedTab === 0 && (
            <div className={style.tableSection}>
              <div className={style.leftSection}>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Item Name</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      className={style.textfield}
                      label="Item Name"
                      fullWidth
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Item Image</p>
                  </div>
                  <div className={style.inputSection}>
                    <div className={style.uploadImageContainer}>
                      <div className={style.uploadButton}>
                        <button onClick={handleImageUpload}>
                          Upload images <IoMdCloudUpload />
                        </button>
                        <input
                          id="stockItemImage"
                          className={style.fileInput}
                          label="Item image"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>
                      <div className={style.uploadedImages}>
                        {itemImages && itemImages.length > 0
                          ? itemImages.map((img, i) => {
                              return (
                                <div className={style.uploadImage}>
                                  <img
                                    key={i}
                                    style={{ width: "100px", height: "100px" }}
                                    src={img}
                                    alt=""
                                  />
                                  <div className={style.replaceImageOverlay}>
                                    {/* Replace image button */}
                                    <TbLayersSubtract
                                      title="replace image"
                                      onClick={() =>
                                        document
                                          .getElementById(
                                            `replaceImageInput-${i}`
                                          )
                                          .click()
                                      }
                                    />
                                    <input
                                      id={`replaceImageInput-${i}`}
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={(e) =>
                                        handleReplaceImage(i, e.target.files[0])
                                      }
                                    />
                                  </div>
                                  <div className={style.uploadImageOverlay}>
                                    <RiDeleteBin6Line
                                      title="delete image"
                                      onClick={() => {
                                        handleDeleteImage(i);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Short Description</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      label="Short Description"
                      fullWidth
                      multiline
                      rows={2}
                      value={shortDesc}
                      onChange={(e) => {
                        setShortDesc(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Long Description</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      label="Long Description"
                      fullWidth
                      multiline
                      rows={4}
                      value={longDesc}
                      onChange={(e) => {
                        setLongDesc(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Item Type</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={itemType}
                      onChange={(e) => {
                        setItemType(e.target.value);
                      }}
                      select
                      label="Item Type"
                      fullWidth
                    >
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Service">Service</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.fixedAsset}>
                    <input
                      type="checkbox"
                      id="fixedAsset"
                      className={style.containerCheck}
                      checked={fixedAsset}
                      disabled={transferableAsset}
                      onChange={(e) => {
                        setFixedAsset(e.target.checked);
                        if (e.target.checked) {
                          setTransferableAsset(false);
                        }
                      }}
                    />
                    <label htmlFor="fixedAsset">Is Fixed Asset</label>
                  </div>
                  <div className={style.transferableAsset}>
                    <input
                      type="checkbox"
                      id="transferableAsset"
                      className={style.containerCheck}
                      checked={transferableAsset}
                      disabled={fixedAsset}
                      onChange={(e) => {
                        setTransferableAsset(e.target.checked);
                        if (e.target.checked) {
                          setFixedAsset(false);
                        }
                      }}
                    />
                    <label htmlFor="transferableAsset">
                      Is Transferable Asset
                    </label>
                  </div>
                  <div className={style.manufacturingItem}>
                    <input
                      type="checkbox"
                      id="manufacturingItem"
                      className={style.containerCheck}
                      checked={manufacturingItem}
                      onChange={(e) => {
                        setManufacturingItem(e.target.checked);
                      }}
                    />
                    <label htmlFor="manufacturingItem">
                      Is Manufacturing Item
                    </label>
                  </div>
                  <div className={style.maintainStock}>
                    <input
                      type="checkbox"
                      id="maintainStock"
                      className={style.containerCheck}
                      checked={maintainStock}
                      onChange={(e) => {
                        setMaintainStock(e.target.checked);
                      }}
                    />
                    <label htmlFor="maintainStock">Maintain Stock</label>
                  </div>
                </div>
                {!fixedAsset && !transferableAsset && (
                  <>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        <p>Varient</p>
                      </div>
                      <div className={style.inputSection}>
                        <TextField
                          select
                          label="Varient Exist"
                          fullWidth
                          value={variantExist}
                          onChange={(e) => {
                            setVariantExist(e.target.value);
                          }}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      </div>
                    </div>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        {" "}
                        <p>Making Cost</p>
                      </div>
                      <div className={style.inputSection}>
                        {" "}
                        <TextField
                          value={makingCost}
                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              setMakingCost(e.target.value);
                            }
                          }}
                          label="Making Cost"
                          fullWidth
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={style.rightSection}>
                {!fixedAsset && !transferableAsset && (
                  <>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        {" "}
                        <p>Inventory Cost</p>
                      </div>
                      <div className={style.inputSection}>
                        <TextField
                          value={inventoryCost}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (validateNumber(e.target.value)) {
                              setInventoryCost(value);
                            }
                          }}
                          label="Inventory Cost"
                          fullWidth
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Purchase Price</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={purchasePrice}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setPurchasePrice(value);
                        }
                      }}
                      label="Purchase Price"
                      fullWidth
                    />
                  </div>
                </div>
                {!fixedAsset && !transferableAsset && (
                  <>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        <p>Sale Price</p>
                      </div>
                      <div className={style.inputSection}>
                        <TextField
                          value={salePrice}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (validateNumber(e.target.value)) {
                              setSalePrice(value);
                            }
                          }}
                          label="Sale Price"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        {" "}
                        <p>Sale Discount Percentage</p>
                      </div>
                      <div className={style.inputSection}>
                        <TextField
                          value={saleDiscountPercentage}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (validateNumber(e.target.value)) {
                              setSaleDiscountPercentage(value);
                            }
                          }}
                          label="Sale Discount Percentage"
                          fullWidth
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>Purchase Tax Percentage</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={purchaseTaxPercentage}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (validateNumber(e.target.value)) {
                          setPurchasePercentage(value);
                        }
                      }}
                      label="Purchase Tax Percentage"
                      fullWidth
                    />
                  </div>
                </div>
                {!fixedAsset && !transferableAsset && (
                  <>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        <p>Sale Tax Percentage</p>
                      </div>
                      <div className={style.inputSection}>
                        <TextField
                          value={SaleTaxPercentage}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (validateNumber(e.target.value)) {
                              setSaleTaxPercentage(value);
                            }
                          }}
                          label="Sale Tax Percentage"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        <p> Tax Type</p>
                      </div>
                      <div className={style.inputSection}>
                        <label>
                          <input
                            className={style.radioButton}
                            type="radio"
                            name="taxType"
                            value="true"
                            checked={taxType === "true"}
                            onChange={(e) => {
                              setTaxType(e.target.value);
                            }}
                          />
                          Inclusive
                        </label>
                        <label>
                          <input
                            className={style.radioButton}
                            type="radio"
                            name="taxType"
                            value="false"
                            checked={taxType === "false"}
                            onChange={(e) => {
                              setTaxType(e.target.value);
                            }}
                          />
                          Exclusive
                        </label>
                      </div>
                    </div>
                    <div></div>
                  </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>HSN Code</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={hsnCode}
                      onChange={(e) => {
                        setHsnCode(e.target.value);
                      }}
                      label="HSN Code"
                      fullWidth
                    />
                  </div>
                </div>
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    <p>QR Code</p>
                  </div>
                  <div className={style.barCodeinputSection}>
                    <TextField
                      value={barCode}
                      onChange={(e) => {
                        setBarCode(e.target.value);
                      }}
                      label="QR Code"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <MdOutlineQrCodeScanner
                              style={{ cursor: "pointer" }}
                              size={24}
                              title="Scan Here"
                              onClick={() => setScannerVisible(true)}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <Button
                    className={style.barCodePrint}
                    disabled={isDisabled}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                  {/* QR Code canvas rendered here */}
                  <div style={{ display: "none" }} ref={qrCodeCanvasRef}>
                    <QRCodeCanvas value={barCode} size={256} />
                  </div>
                </div>

                <Modal
                  open={scannerVisible}
                  aria-labelledby="create-order-modal"
                  aria-describedby="create-order-form"
                  onClose={() => {
                    setScannerVisible(false);
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "10%", // Position near the top
                      left: "50%", // Center horizontally
                      transform: "translate(-50%, 0)", // Adjust centering
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <QrScanner onScan={handleScan} onError={handleError} />
                    <Button onClick={() => setScannerVisible(false)}>
                      Close Scanner
                    </Button>
                  </div>
                </Modal>

                {!fixedAsset && !transferableAsset && (
                  <>
                    <div className={style.inputContainer}>
                      <div className={style.label}>
                        {" "}
                        <p>Category</p>
                      </div>
                      <div className={style.inputSection}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select category"
                            multiple
                            value={selectedCategory}
                            onChange={(event) =>
                              setSelectedCategory(event.target.value)
                            } // Add this prop
                            MenuProps={menuProps}
                          >
                            {categoryData &&
                              categoryData.length > 0 &&
                              categoryData.map((item) => {
                                return (
                                  <MenuItem
                                    key={item.category_id}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                    value={item.category_id}
                                  >
                                    {" "}
                                    {item.name}
                                    <Checkbox
                                      name={item.category_id}
                                      id={item.category_id}
                                      onChange={() =>
                                        handleCheckboxChange(item.category_id)
                                      }
                                      checked={selectedCategory.includes(
                                        item.category_id
                                      )}
                                    />
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
                <div className={style.inputContainer}>
                  <div className={style.label}>
                    {" "}
                    <p>Status</p>
                  </div>
                  <div className={style.inputSection}>
                    <TextField
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      select
                      label="Status"
                      fullWidth
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={style.attributeSection}>
              <div className={style.attributeSelectionHeader}>
                <div className={style.attributeSelection}>
                  <TextField
                    select
                    label="Select Category"
                    fullWidth
                    margin="normal"
                    value={selectedAttribute}
                    onChange={(e) => {
                      setSelectedAttribute(e.target.value);
                    }}
                  >
                    {attributeData.map((atr) => {
                      return (
                        <MenuItem
                          key={atr.attribute_code}
                          value={atr.attribute_code}
                        >
                          {atr.attribute_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className={style.attributeAddButton}>
                  <button
                    disabled={selectedAttribute === ""}
                    onClick={() => {
                      handleAddAttribute();
                      attrTypeList();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className={style.attributeSectionBody}>
                {attributeSections.map((attribute, index) => {
                  const typesForAttribute =
                    selectedType[attribute.attributeID] || [];
                  const isTypeVisible =
                    showType[attribute.attributeID] || false;

                  return (
                    <div key={index} className={style.existingAttributeSection}>
                      <div className={style.existingAttributeHeader}>
                        <p>{attribute.name}</p>
                        <div className={style.attributeHeadIcon}>
                          {attribute.isExpanded ? (
                            <FaCaretUp
                              onClick={() =>
                                handleExpand(attribute.attributeID)
                              }
                            />
                          ) : (
                            <FaCaretDown
                              onClick={() =>
                                handleExpand(attribute.attributeID)
                              }
                            />
                          )}
                          <RiDeleteBinLine
                            onClick={() => {
                              handleDeleteAttribute(attribute.attributeID);
                            }}
                          />
                          <BiEditAlt
                            onClick={() => handleExpand(attribute.attributeID)}
                          />
                        </div>
                      </div>
                      {attribute.isExpanded && (
                        <>
                          <div className={style.existingAttributeBody}>
                            <div className={style.existingAttributeBodyLeft}>
                              {attribute.type === "custom" ? (
                                <>
                                  <div className={style.customAttributeName}>
                                    <span>Name</span>:<p></p>
                                  </div>
                                  <div
                                    className={style.customAttributeNameInput}
                                  >
                                    <input />
                                  </div>
                                </>
                              ) : (
                                <div className={style.existingAttributeName}>
                                  <span>Name</span>:<p>{attribute.name}</p>
                                </div>
                              )}
                            </div>
                            <div className={style.existingAttributeBodyRight}>
                              <div className={style.existingAttributeName}>
                                <span>Value(s)</span>:
                              </div>
                              {attribute.type === "custom" ? (
                                <div
                                  className={
                                    style.customAttributeSelectionContainer
                                  }
                                >
                                  <textarea placeholder="Enter some text or attributes by (|) separating values" />
                                </div>
                              ) : (
                                <div
                                  className={
                                    style.existingAttributeSelectionContainer
                                  }
                                >
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowType((prevState) => ({
                                        ...prevState,
                                        [attribute.attributeID]: !isTypeVisible,
                                      }));
                                    }}
                                    className={style.existingAttributeSelection}
                                  >
                                    {typesForAttribute.length > 0 ? (
                                      typesForAttribute.map((id, i) => {
                                        const typeName =
                                          attribute.attributeType.find(
                                            (type) =>
                                              type.type_code === id.type_code
                                          )?.type_name;
                                        return (
                                          <div
                                            key={i}
                                            className={style.selectedTypeCard}
                                          >
                                            <p>{typeName}</p>
                                            <IoIosCloseCircleOutline
                                              onClick={() =>
                                                handleRemoveType(
                                                  attribute.attributeID,
                                                  id.type_code
                                                )
                                              }
                                            />
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className={style.placeHolder}>
                                        <p>Select Type</p>
                                      </div>
                                    )}
                                  </div>
                                  {isTypeVisible && (
                                    <div
                                      ref={typeRef}
                                      className={style.typeContainer}
                                    >
                                      {attribute.attributeType.map(
                                        (type, i) => {
                                          return (
                                            <div
                                              onClick={() => {
                                                handleTypeSelect(
                                                  attribute.attributeID,
                                                  type
                                                );
                                                setShowType(false);
                                              }}
                                              key={i}
                                              className={style.typeValue}
                                            >
                                              <p>{type.type_name}</p>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={style.existingAttributeButton}>
                            <button
                              disabled={
                                !changedAttributeList.includes(
                                  attribute.attributeID
                                )
                              }
                              onClick={() => {
                                handleSaveAttributes(attribute.attributeID);
                              }}
                            >
                              Save Attributes
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={style.attributeSection}>
              <div className={style.variantSelectionHeader}>
                <div className={style.attributeSelection}></div>
                <div className={style.attributeAddButton}>
                  <button
                    onClick={() => {
                      handleVarient();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className={style.attributeSectionBody}>
                {variantSections.map((item, index) => {
                  return (
                    <div key={index} className={style.existingAttributeSection}>
                      <div className={style.existingVarientHeader}>
                        <div className={style.varientLabelDropdown}>
                          {renderVariantDropdowns(item.variantID)}
                        </div>
                        <div className={style.attributeHeadIcon}>
                          {item.isExpanded ? (
                            <FaCaretUp
                              onClick={() =>
                                handleVarientExpand(item.variantID)
                              }
                            />
                          ) : (
                            <FaCaretDown
                              onClick={() =>
                                handleVarientExpand(item.variantID)
                              }
                            />
                          )}
                          <RiDeleteBinLine
                            onClick={() => handleVarientDelete(item.variantID)}
                          />
                          <BiEditAlt />
                        </div>
                      </div>
                      {item.isExpanded && (
                        <>
                          <div className={style.existingVarientBody}>
                            <div className={style.uploadImageContainer}>
                              <div className={style.imageUpload}>
                                <label htmlFor="imageUpload">
                                  <FaImage
                                    title="Upload Image"
                                    style={{
                                      color: "#59293a",
                                      fontSize: "70px",
                                    }}
                                    onClick={() => handleVariantImage(index)}
                                  />
                                </label>
                                <input
                                  type="file"
                                  id={"variantImageUpload_" + index}
                                  name="imageUpload"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  multiple
                                  onChange={(e) =>
                                    handleVariantImageUpload(e, item.variantID)
                                  }
                                />
                              </div>
                              <div className={style.uploadedImages}>
                                {item.image?.length > 0
                                  ? item.image.map((img, i) => {
                                      return (
                                        <div className={style.uploadImage}>
                                          <img
                                            key={i}
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                            src={img}
                                            alt=""
                                          />
                                          <div
                                            className={style.uploadImageOverlay}
                                          >
                                            <RiDeleteBin6Line
                                              onClick={() =>
                                                handleDeleteVariantImage(
                                                  item.variantID,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            </div>
                            <div className={style.priceFields}>
                              <TextField
                                label="Regular Price"
                                fullWidth
                                margin="normal"
                                value={item.regular_price}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (validateNumber(e.target.value)) {
                                    handleVariantChange(
                                      item.variantID,
                                      "regular_price",
                                      inputValue
                                    );
                                  }
                                }}
                              />
                              <TextField
                                label="Sale Price"
                                fullWidth
                                margin="normal"
                                value={item.sale_price}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (validateNumber(e.target.value)) {
                                    handleVariantChange(
                                      item.variantID,
                                      "sale_price",
                                      inputValue
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div className={style.stockStatus}>
                              <TextField
                                select
                                label="Stock Status"
                                fullWidth
                                margin="normal"
                                value={item.stock_status}
                                onChange={(e) =>
                                  handleVariantChange(
                                    item.variantID,
                                    "stock_status",
                                    e.target.value
                                  )
                                }
                              >
                                <MenuItem value="in-stock">In Stock</MenuItem>
                                <MenuItem value="out-of-stock">
                                  Out of Stock
                                </MenuItem>
                              </TextField>
                            </div>

                            <div className={style.descriptionField}>
                              <TextField
                                label="Description"
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                                value={item.description}
                                onChange={(e) =>
                                  handleVariantChange(
                                    item.variantID,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className={style.existingAttributeButton}>
                            <button
                              onClick={() => {
                                handleSaveVariant(item.variantID);
                              }}
                            >
                              Add Variants
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Typography>
      </div>
    </div>
  );
}

export default ItemUpdate;
