import React, { useEffect, useState } from "react";
import style from "./CustomerGroup.module.css";
import { IoSearch } from "react-icons/io5";
import {
  Alert,
  Box,
  FormControl,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import { Link } from "react-router-dom";
import { TbCategoryPlus } from "react-icons/tb";
import { MdOutlinePersonSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

function CustomerGroup() {
  const [hubData, setHubData] = useState([]);
  const [selectedHub, setSelectedHub] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [createLocationId, setCreateLocationId] = useState("");
  const [createCustomerGroupName, setCreateCustomerGroupName] = useState("");
  const [createPopup, setCreatePopup] = useState("");
  const [createOrgId, setCreateOrgId] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [updatePopup, setUpdatePopup] = useState("");
  const [updateCgName, setUpdateCgName] = useState("");
  const [updateCgStatus, setUpdateCgStatus] = useState("");
  const [updateCgId, setUpdateCgId] = useState("");
  const [deleteCgId, setDeleteCgId] = useState("");
  const [deletePopup, setDeletePopup] = useState("");
  const [deleteCgName, setDeleteCgName] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roleFail, setRoleFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [customerGroupSuccessSnack, setCustomerGroupSuccessSnack] =
    useState(false);
  const [customerGroupDeletionSnack, setCustomerGroupDeletionSnack] =
    useState(false);
  const [customerGroupUpdationSnack, setCustomerGroupUpdationSnack] =
    useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [customerGroupView, setCustomerGroupView] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("access-token"));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const createValidation = () => {
    // if (!createOrgId) {
    //     setErrorMessage("Organization is required");
    //     return false;
    // }
    // if (!createLocationId) {
    //     setErrorMessage("Location is required");
    //     return false;
    // }
    if (!createCustomerGroupName) {
      setErrorMessage("Customer Group Name is required");
      return false;
    }
    return true;
  };

  const updateValidation = () => {
    if (!updateCgName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    return true;
  };

  async function customerGroupList() {
    setLoading(true);
    await fetch(`${baseUrl}/customer/group/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customerData");
        if (data.status === true) {
          setCustomerData(data.data);
        } else {
          setCustomerData([]);
        }
      })
      .finally(() => setLoading(false)); 
  }

  async function customerGroupCreation() {
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/customer/group/creation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          customer_group_name: createCustomerGroupName,
          status: status,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "customerGroupData");
          if (data.status === true) {
            setCreatePopup(false);
            customerGroupList();
            setCustomerGroupSuccessSnack(true);
            setCreateCustomerGroupName("");
            setStatus("");
            // setSelectedHub(createOrgId);
            // setSelectedLocation(createLocationId);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function customerGroupUpdation() {
    const reqData = {
      customergroup_id: updateCgId,
      customer_group_name: updateCgName,
      status: updateCgStatus,
    };
    if (!updateValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/customer/group/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "updateData");
          if (data.status === true) {
            setUpdatePopup(false);
            customerGroupList();
            setCustomerGroupUpdationSnack(true);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function customerGroupDelete() {
    await fetch(`${baseUrl}/customer/group/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        customergroup_id: deleteCgId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "deleteData");
        if (data.status === true) {
          setDeletePopup(false);
          customerGroupList();
          setCustomerGroupDeletionSnack(true);
        } else {
          setApiDescription(data.description);
          setRoleFail(true);
        }
      });
  }

  async function customerGroupSearch() {
    const reqData = {
      search_value: searchValue,
    };
    await fetch(`${baseUrl}/customer/group/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "searchData");
        if (data.status === true) {
          setCustomerData(data.data);
          setCustomerGroupView(true);
        } else {
          setCustomerData([]);
          setCustomerGroupView(false);
        }
      });
  }

  useEffect(() => {
    // hubList();
    customerGroupList();
  }, []);

  useEffect(() => {
    if (searchValue !== "") {
      customerGroupSearch();
    } else {
      customerGroupList();
      setCustomerGroupView(true);
    }
  }, [searchValue]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          customerGroupSuccessSnack ||
          customerGroupDeletionSnack ||
          customerGroupUpdationSnack
        }
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCustomerGroupSuccessSnack(false);
          setCustomerGroupDeletionSnack(false);
          setCustomerGroupUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {customerGroupSuccessSnack
            ? "Customer Group created successfully"
            : customerGroupDeletionSnack
            ? "Customer Group deleted successfully"
            : customerGroupUpdationSnack
            ? "Customer Group updated successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roleFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Customer Group</p>
        </div>

        <div className={style.search}>
          <IoSearch className={style.searchIcon} />
          <input
            type="text"
            placeholder="search"
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
        <button
          onClick={() => {
            setCreatePopup(true);
          }}
          className={style.buttonSection}
        >
          Add Customer Group
        </button>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.tableSection}>
        {/* {customerGroupView ? ( */}
        <TableContainer component={Paper} className={style.table}>
          {/* <div className={style.tableHeading}> Customer Group </div> */}
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead
              style={{ backgroundColor: "#f7eedf" }}
              className={style.tableHead}
            >
              <TableRow>
                {/* <TableCell></TableCell> */}
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData.map((item) => (
                <TableRow key={item.customergroup_id}>
                  <TableCell component="th" scope="row">
                    {item.customer_group_name}
                  </TableCell>
                  <TableCell align="left">{item.status}</TableCell>
                  <TableCell align="left">
                    <BiEditAlt
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      title="Edit"
                      onClick={() => {
                        setUpdatePopup(true);
                        setUpdateCgId(item.customergroup_id);
                        setUpdateCgName(item.customer_group_name);
                        setUpdateCgStatus(item.status);
                      }}
                    />
                    <RiDeleteBinLine
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        marginLeft: "15px",
                      }}
                      title="Delete"
                      onClick={() => {
                        setDeletePopup(true);
                        setDeleteCgId(item.customergroup_id);
                        setDeleteCgName(item.customer_group_name);
                      }}
                    />
                    <MdOutlinePersonSearch
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        marginLeft: "15px",
                      }}
                      title="View Mapped Customers"
                      onClick={() => {
                        navigate("/customer/under/group", {
                          state: { selectedGroupId: item.customergroup_id },
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
       )}

      {createPopup && (
        <Modal
          open={createPopup}
          onClose={() => {
            setCreatePopup(false);
            setCreateCustomerGroupName("");
            setStatus("");
          }}
          aria-labelledby="add-hub-modal"
          aria-describedby="add-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-hub-modal">Add Customer Group</h2>
            <div className={style.modalContent}>
              <TextField
                label="Customer Group Name"
                fullWidth
                multiline
                margin="normal"
                value={createCustomerGroupName}
                onChange={(e) => {
                  setCreateCustomerGroupName(e.target.value);
                }}
              />
              {/* <div className={style.label}> */}
              <TextField
                label="Status"
                select
                className={style.textfield}
                fullWidth
                margin="normal"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
              {/* </div> */}
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    customerGroupCreation();
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                    setCreateCustomerGroupName("");
                    setStatus("");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false);
          }}
          aria-labelledby="edit-hub-modal"
          aria-describedby="edit-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="edit-hub-modal">Edit Customer Group</h2>
            <div className={style.modalContent}>
              <TextField
                label="Customer Group Name"
                fullWidth
                margin="normal"
                value={updateCgName}
                onChange={(e) => {
                  setUpdateCgName(e.target.value);
                }}
              />
              <TextField
                select
                label="Status"
                fullWidth
                margin="normal"
                value={updateCgStatus}
                onChange={(e) => {
                  setUpdateCgStatus(e.target.value);
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.modalActions}>
                <button
                  className={style.EditSub}
                  variant="contained"
                  onClick={() => {
                    customerGroupUpdation();
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.EditClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>
                Are you sure you want to delete {deleteCgName}?
              </p>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    customerGroupDelete();
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default CustomerGroup;
