import style from './Loader.module.css'

const Loader = () => {
  return (
    <div className={style.container}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 200 200"
        color="#59293a"
        fill="none"
      >
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
        </defs>

        <g strokeWidth="16">
          <path
            stroke="url(#spinner-secondHalf)"
            d="M 8 100 A 92 92 0 0 1 192 100"
          />
          <path
            stroke="url(#spinner-firstHalf)"
            d="M 192 100 A 92 92 0 0 1 8 100"
          />
          <path
            stroke="currentColor"
            strokeLinecap="round"
            d="M 8 100 A 92 92 0 0 1 8 98"
          />
        </g>

        <animateTransform
          from="0 0 0"
          to="360 0 0"
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1300ms"
        />
      </svg>
    </div>
  );
};

export default Loader;
