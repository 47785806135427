import style from "./Roles.module.css";
import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { baseUrl } from "../Url";
import { Alert, Modal, Snackbar, TextField } from "@mui/material";
import PermissionContent from "../PermissionsContent/PermissionsContent ";
import ModuleListing from "../ModuleLisiting/ModuleListing";
import { GrUserAdmin } from "react-icons/gr";
import Loader from "../Loader/Loader";

function Roles() {
  const [createPopup, setCreatePopup] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [roleEdit, setRoleEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [roleDelete, setRoleDelete] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roleSuccessSnack, setRoleSuccessSnack] = useState(false);
  const [roleDeletionSnack, setRoleDeletionSnack] = useState(false);
  const [roleUpdationSnack, setRoleUpdationSnack] = useState(false);
  const [roleFail, setRoleFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [roleError, setRoleError] = useState("");
  const [roleView, setRoleView] = useState(true);
  const [items, setItems] = useState([]);
  const [showPermissionContent, setShowPermissionContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePermissionContent = (roleCode, roleName, roleId) => {
    setShowPermissionContent(!showPermissionContent);
    localStorage.setItem("role_code", roleCode);
    localStorage.setItem("role_name", roleName);
    localStorage.setItem("role_id", roleId);
  };

  const clearSelectedModules = () => {
    localStorage.removeItem("selectedModules");
  };

  const createValidation = () => {
    if (!roleName) {
      setErrorMessage("Role Name is required");
      return false;
    }
    if (!description) {
      setErrorMessage("Description is required");
      return false;
    }
    return true;
  };
  const updateValidation = () => {
    if (!editName) {
      setErrorMessage("Role Name is required");
      return false;
    }
    if (!editDescription) {
      setErrorMessage("Description is required");
      return false;
    }

    return true;
  };

  const openPopup = () => {
    setCreatePopup(true);
  };

  const closePopup = () => {
    setCreatePopup(false);
    setRoleName("");
    setDescription("");
  };

  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  async function createRole() {
    const reqData = {
      role_name: roleName,
      description: description,
    };
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      var storedToken = JSON.parse(localStorage.getItem("access-token"));
      await fetch(`${baseUrl}/role/creation`, {
        method: "POST",
        body: JSON.stringify(reqData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "data");
          if (data.status === true) {
            setCreatePopup(false);
            setRoleSuccessSnack(true);
            roleListing();
            setRoleName("");
            setDescription("");
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function roleListing() {
    const storedToken = JSON.parse(localStorage.getItem("access-token"));
    setLoading(true);
    await fetch(`${baseUrl}/roles/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setRoleData(data.data);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      })
      .finally(() => setLoading(false)); 
  }

  async function roleUpdate() {
    const reqData = {
      role_id: editId,
      role_name: editName,
      description: editDescription,
    };
    if (!updateValidation()) {
      setValidationFail(true);
      return;
    } else {
      var storedToken = JSON.parse(localStorage.getItem("access-token"));
      await fetch(`${baseUrl}/role/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "data");
          if (data.status === true) {
            setEditName("");
            setEditDescription("");
            roleListing();
            setRoleEdit(false);
            setRoleUpdationSnack(true);
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        });
    }
  }

  async function roleDeletion() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/role/delete`, {
      method: "POST",
      body: JSON.stringify({
        role_id: deleteId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "data");
        if (data.status === true) {
          roleListing();
          setRoleDelete(false);
          setRoleDeletionSnack(true);
        } else {
          setApiDescription(data.description);
          setRoleFail(true);
        }
      });
  }

  async function roleSearch(value) {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/role/search`, {
      method: "POST",
      body: JSON.stringify({
        search_value: value,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "searchData");
        if (data.status === true) {
          setRoleData(data.data);
          setRoleView(true);
        } else {
          setRoleError(data.description);
          setRoleView(false);
        }
      });
  }

  async function fetcFeaturehData() {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/features/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const data = await response.json();
      setItems(data.data);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }

  useEffect(() => {
    roleListing();
    fetcFeaturehData();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Role Management</p>
        </div>

        <div className={style.search}>
          <IoSearch className={style.searchIcon} />

          <input
            type="text"
            placeholder="search"
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              const searchInputValue = e.target.value;
              if (searchInputValue !== "") {
                roleSearch(searchInputValue);
              } else {
                roleListing();
                setRoleView(true);
              }
              setSearchValue(searchInputValue);
            }}
          />
        </div>
        <button onClick={openPopup} className={style.buttonSection}>
          Add Role
        </button>
      </div>

      <div className={style.containerbox}>
      {loading ? (
              <Loader />
            ) : (
        <div className={style.tableSection}>
          {roleView ? (
            <TableContainer
              className={style.tableContainer}
              component={Paper}
              sx={{ width: "100%" }}
            >
              {/* <div className={style.tableHeading}>Roles</div> */}
              <Table>
                <TableHead className={style.tableHead}>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleData.map((item) => (
                    <TableRow key={item.role_name}>
                      <TableCell component="th" scope="row">
                        {item.role_name}
                      </TableCell>
                      <TableCell align="left">{item.description}</TableCell>
                      <TableCell className={style.actionIcons} align="center">
                        <BiEditAlt
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          title="Edit"
                          onClick={() => {
                            setRoleEdit(true);
                            setEditName(item.role_name);
                            setEditDescription(item.description);
                            setEditId(item.role_id);
                          }}
                        />
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            marginLeft: "15px",
                          }}
                          title="Delete"
                          onClick={() => {
                            setRoleDelete(true);
                            setDeleteId(item.role_id);
                            setDeleteName(item.role_name);
                          }}
                        />

                        <GrUserAdmin
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            marginLeft: "15px",
                          }}
                          title="Set Permissions"
                          onClick={() => {
                            togglePermissionContent(
                              item.role_code,
                              item.role_name,
                              item.role_id
                            );
                            clearSelectedModules();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="error" color="warning">
              No role found, please check your input.
            </Alert>
          )}
        </div>
         )}

        <div className={style.PermBox}>
          {showPermissionContent && (
            <ModuleListing roleName={localStorage.getItem("role_name")} />
          )}
        </div>
      </div>

      {createPopup && (
        <Modal
          open={createPopup}
          onClose={closePopup}
          aria-labelledby="add-hub-modal"
          aria-describedby="add-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-hub-modal">Add Role</h2>
            <div className={style.modalContent}>
              <TextField
                label="Role Name"
                value={roleName}
                onChange={handleRoleNameChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    createRole();
                  }}
                >
                  Submit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {roleEdit && (
        <Modal
          open={roleEdit}
          onClose={() => {
            setRoleEdit(false);
          }}
          aria-labelledby="add-hub-modal"
          aria-describedby="add-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-hub-modal">Update Role</h2>
            <div className={style.modalContent}>
              <TextField
                label="Role"
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editDescription}
                onChange={(e) => {
                  setEditDescription(e.target.value);
                }}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    roleUpdate();
                  }}
                >
                  Submit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setRoleEdit(false);
                    setRoleName("");
                    setDescription("");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {roleDelete && (
        <Modal
          open={roleDelete}
          onClose={() => {
            setRoleDelete(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p>Are you sure you want to delete {deleteName}?</p>

              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    roleDeletion();
                  }}
                >
                  SUbmit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setRoleDelete(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={roleSuccessSnack || roleDeletionSnack || roleUpdationSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleSuccessSnack(false);
          setRoleDeletionSnack(false);
          setRoleUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {roleSuccessSnack
            ? "Role created successfully"
            : roleDeletionSnack
            ? "Role deleted successfully"
            : roleUpdationSnack
            ? "Role updated successfully"
            : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roleFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Roles;
