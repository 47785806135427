import React, { useEffect, useState } from 'react'
import style from './SubCategory.module.css'
import { IoSearch } from 'react-icons/io5';
import { Alert, Box, Button, FormControl, MenuItem, Modal, OutlinedInput, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { BiEditAlt, BiFoodMenu } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { baseUrl } from '../Url';
import { Link, useLocation } from 'react-router-dom';
import { TbCategoryPlus } from 'react-icons/tb';
import { useOutletContext } from 'react-router-dom';
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";

function SubCategory() {
  const { selectedNewHub } = useOutletContext();

  const [subcategoryData, setSubcategoryData] = useState([]);
  const [createPopup, setCreatePopup] = useState("");
  const [createName, setCreateName] = useState("");
  const [createDescription, setCreateDescription] = useState("");
  const [newSubcategoryId, setNewSubcategoryId] = useState("");
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [editId, setEditId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [roleFail, setRoleFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [subcategorySuccessSnack, setSubcategorySuccessSnack] = useState(false);
  const [subcategoryDeletionSnack, setSubcategoryDeletionSnack] = useState(false);
  const [subcategoryUpdationSnack, setSubcategoryUpdationSnack] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noSubCategoriesFound, setNoSubCategoriesFound] = useState(false);
  const [categoryImage, setCategoryImage] = useState({ preview: "", data: "" });
  const [ediCategoryImage, setEditCategoryImage] = useState({ preview: "", data: "" });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get('orgId');
  const categoryId = queryParams.get('categoryId');
  console.log(orgId, categoryId, 'useparms');
  const token = JSON.parse(localStorage.getItem("access-token"));


  const createValidation = () => {
    if (!categoryImage.data) {
      setErrorMessage("Image is required");
      return false;
    }
    if (!createName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!createDescription) {
      setErrorMessage("Description is required");
      return false;
    }
    return true;
  }

  const updateValidation = () => {
    if (!ediCategoryImage.data) {
      setErrorMessage("Image is required");
      return false;
    }
    if (!editName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!editDescription) {
      setErrorMessage("Description is required");
      return false;
    }
    return true;
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setCategoryImage(img);
  };
  const handleEditFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setEditCategoryImage(img);
  };

  async function subCategoryList(orgId, categoryId) {
    console.log(orgId, categoryId, 'reqbody of list');
    const reqData = {
      org_id: orgId,
      category_id: categoryId
    }
    console.log(reqData, 'list req');
    await fetch(`${baseUrl}/categories/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'subcateogoryList');
        if (data.status === true) {
          setSubcategoryData(data.data);
          console.log(data.data, 'data-data');
        }
      })
  }

  async function createSubCategory(selectedNewHub, categoryId) {
    if (!createValidation()) {
      setValidationFail(true);
      return
    }
    let formData = new FormData()
    formData.append('image', categoryImage.data);
    formData.append('org_id', selectedNewHub);
    formData.append('parent_id', categoryId);
    formData.append('createName', createName);
    formData.append('description', createDescription);

    try {
      await fetch(`${baseUrl}/categories/create`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      })
        .then((res) => res.json())
        .then((data) => {
          
          console.log(data, 'createdData');
          if (data.status === true) {
            setNewSubcategoryId(data.category_id);
            subCategoryList(selectedNewHub, categoryId);
            setCreatePopup(false)
            setSubcategorySuccessSnack(true)
            setCreateName("")
            setCreateDescription("")
            setCategoryImage({ preview: "", data: "" });
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        })
    }catch (error) {
      console.error("Error creating subcategory:", error);
    }
  }

  async function subCategoryUpdate() {
    let formData = new FormData()
    if (ediCategoryImage.data !== "") {
      formData.append('image', ediCategoryImage.data);
    }
    formData.append('category_id', editId);
    formData.append('name', editName);
    formData.append('description', editDescription);
    formData.append('status', editStatus);
    if (!updateValidation()) {
      setValidationFail(true);
      return
    } else {
      await fetch(`${baseUrl}/category/edit`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, 'editCategoryData');
          if (data.status === true) {
            setUpdatePopup(false)
            subCategoryList(selectedNewHub, categoryId)
            setSubcategoryUpdationSnack(true)
          } else {
            setApiDescription(data.description);
            setRoleFail(true);
          }
        })
    }
  }

  async function subCategoryDelete() {
    await fetch(`${baseUrl}/categories/delete`, {
      method: "POST",
      body: JSON.stringify({
        category_id: deleteId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'deleteData');
        if (data.status === true) {
          setDeletePopup(false);
          subCategoryList(selectedNewHub, categoryId)
          setSubcategoryDeletionSnack(true)
        } else {
          setApiDescription(data.description);
          setRoleFail(true);
        }
      })
  }

  async function subCategorySearch(searchOrgId, searchValue) {
    const reqData = {
      org_id: searchOrgId,
      search_value: searchValue,
      category_id: categoryId
    }
    await fetch(`${baseUrl}/categories/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'deleteData');
        if (data.status === true) {
          setSubcategoryData(data.data);
          setNoSubCategoriesFound(false);
        } else if (searchValue === '') {
          subCategoryList(orgId, categoryId);
          setNoSubCategoriesFound(false);
        } else {
          setNoSubCategoriesFound(true)
          setSubcategoryData([]);
        }
      })
  }



  useEffect(() => {
    subCategoryList(selectedNewHub, categoryId);
  }, [selectedNewHub, categoryId]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={subcategorySuccessSnack || subcategoryDeletionSnack || subcategoryUpdationSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setSubcategorySuccessSnack(false);
          setSubcategoryDeletionSnack(false);
          setSubcategoryUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {subcategorySuccessSnack
            ? "Category created successfully"
            : subcategoryDeletionSnack
              ? "Category deleted successfully"
              : subcategoryUpdationSnack
                ? "Category updated successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roleFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setRoleFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Sub Category</p>
        </div>
      <div className={style.headerActions}>
        
          <div className={style.search}>
            <IoSearch className={style.searchIcon} />
            <input
              type="text"
              placeholder=""
              className={style.searchInput}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
                subCategorySearch(selectedNewHub, e.target.value);
              }}
  
            />
          </div>
          <div className={style.buttonSection}>
          <button
            onClick={() => {
              setCreatePopup(true)
            }}
          >
            Add Sub Category
          </button>
        </div>
      </div>
      </div>
      
      <div className={style.tableSection}>
        {!noSubCategoriesFound && (
          <TableContainer component={Paper} className={style.table}>
            {/* <div className={style.tableHeading}> Sub Category </div> */}
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead style={{ backgroundColor: "#f7eedf" }}
                className={style.tableHead}
              >
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>Image</TableCell>
                  <TableCell sx={{ width: "15%" }}>Name</TableCell>
                  <TableCell align="left"  sx={{ width: "34%" }}>Description</TableCell>
                  <TableCell align="left" style={{ width: '15%' }}>Created Date</TableCell>
                  <TableCell align="center"sx={{ width: "10%" }}>Status</TableCell>
                  <TableCell align="center" sx={{ width: "16%" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subcategoryData.map((item) => (
                  <TableRow key={item.category_id}>
                     <TableCell component="th" scope="row">
                        <img src={item.image} alt="Image description" style={{width:"80px",height:"80px"}}/>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell align="left">
                        {item.description.length > 130 ? 
                          item.description.substring(0, 130) + '...' : 
                          item.description}
                      </TableCell>
                      <TableCell align="left" >{item.created_date}</TableCell>
                    <TableCell align="center">
                    {item.status==="active"?<FaRegCircleCheck style={{color:'green',fontSize:"1.35rem"}}/>:item.status==="Inactive"?<IoIosCloseCircleOutline style={{color:'red',fontSize:"1.5rem"}}/>:""}
                      </TableCell>
                    <TableCell align="left"  >
                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
                        <BiEditAlt
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          title="Edit"
                          onClick={() => {
                            setEditId(item.category_id);
                            setEditName(item.name);
                            setEditDescription(item.description);
                            setEditStatus(item.status);
                            setUpdatePopup(true);
                            setEditCategoryImage({ preview: item.image, data: "" });
                          }}
                        />
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          title="Delete"
                          onClick={() => {
                            setDeletePopup(true);
                            setDeleteId(item.category_id);
                            setDeleteName(item.name);
                          }}
                        />
                        {item.category_type === "category" && (
                          <Link to={`/category/subcategory?orgId=${selectedNewHub}&categoryId=${item.category_id}`}>
                            <TbCategoryPlus
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                color: "black"
                              }}
                              title="View Sub category"
                            />
                          </Link>
                        )}
                        {item.category_type === "stockitem" && (
                          <Link to={`/stockitems?orgId=${selectedNewHub}&categoryId=${item.category_id}`}>
                            <BiFoodMenu
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                               
                                color: "black"
                              }}
                              title="View Stock Items"
                            />
                          </Link>
                        )}
                        {item.category_type === "" && (
                          <>
                            <Link to={`/category/subcategory?orgId=${selectedNewHub}&categoryId=${item.category_id}`}>
                              <TbCategoryPlus
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                 
                                  color: "black"
                                }}
                                title="View Sub category"
                              />
                            </Link>
                            <Link to={`/stockitems?orgId=${selectedNewHub}&categoryId=${item.category_id}`}>
                              <BiFoodMenu
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                 
                                  color: "black"
                                }}
                                title="View Stock Items"
                              />
                            </Link>
                          </>
                        )}
                </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {noSubCategoriesFound && (
          <Alert severity="error" color="warning"
            sx={{
              width: "100%",
            }}
          >
            No Sub category found..!
          </Alert>
        )}
      </div>
      {createPopup && (
        <Modal
          open={createPopup}
          onClose={() => {
            setCreatePopup(false)
            setCreateName("")
            setCreateDescription("")
            setCategoryImage({ preview: "", data: "" });
          }}
          aria-labelledby="add-hub-modal"
          aria-describedby="add-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-hub-modal">Add  Sub Category</h2>
            <div className={style.modalContent}>
            {categoryImage.preview !== "" ? (
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  // onClick={handleClick}
                  src={categoryImage.preview}
                  style={{ width: "150px", height: "150px" }}
                  alt="something wrong"
                />
                <button
                    onClick={() => setCategoryImage({ preview: "", data: "" })}
                    style={{
                        position: "absolute",
                        top: "5px",
                        left: "8rem",
                        background: "#d8a5a547",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                    }}
                >
                    &times;
                </button>
                </div>
              ) : (
                <input
                  id="imageInput"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleFileChange}
                  type="file"
                />
              )}
              <TextField
                label="Sub Category Name"
                fullWidth
                margin="normal"
                value={createName}
                onChange={(e) => {
                  setCreateName(e.target.value)
                }}
              />
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={createDescription}
                onChange={(e) => {
                  setCreateDescription(e.target.value)
                }}
              />
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    createSubCategory(orgId, categoryId);
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false)
                    setCreateName("")
            setCreateDescription("")
            setCategoryImage({ preview: "", data: "" });
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false)
            setEditName("");
            setEditDescription("");
            setEditStatus("");
            setEditCategoryImage({ preview: "", data: "" });
          }}
          aria-labelledby="edit-hub-modal"
          aria-describedby="edit-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="edit-hub-modal">Edit Sub Category</h2>
            <div className={style.modalContent}>
            {ediCategoryImage.preview !== "" ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                <img
                    // onClick={handleUpdateImageClick}
                    src={ediCategoryImage.preview}
                    style={{ width: "150px", height: "150px" }}
                    alt="preview"
                />
                <button
                    onClick={() => setEditCategoryImage({ preview: "", data: "" })}
                    style={{
                        position: "absolute",
                        top: "5px",
                        left: "8rem",
                        background: "#d8a5a547",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                    }}
                >
                    &times;
                </button>
            </div>
              ) : (
                <input
                  id="updateImageInput"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleEditFileChange}
                  type="file"
                />
              )}
              <TextField
                label="Sub Category Name"
                fullWidth
                margin="normal"
                value={editName}
                onChange={(e) => {
                  setEditName(e.target.value)
                }}
              />
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={editDescription}
                onChange={(e) => {
                  setEditDescription(e.target.value)
                }}
              />
              <TextField
                select
                label="Status"
                fullWidth
                margin="normal"
                value={editStatus}
                onChange={(e) => {
                  setEditStatus(e.target.value)
                }}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.modalActions}>
                <button
                  className={style.EditSub}
                  variant="contained"
                  onClick={async() => {
                    await subCategoryUpdate()
                    setEditCategoryImage({ preview: "", data: "" });
                    setUpdatePopup(false);
                  }}
                >
                  SUBMIT
                </button>
                <button
                  className={style.EditClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false)
                    setEditName("");
                    setEditDescription("");
                    setEditStatus("");
                    setEditCategoryImage({ preview: "", data: "" });
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>Are you sure you want to delete {deleteName}?</p>
              <div className={style.modalActions}>
                <button
                  variant="contained"
                  onClick={() => {
                    subCategoryDelete();
                  }}
                  className={style.EditSub}
                >
                  Confirm
                </button>
                <button
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                  className={style.EditClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  )
}

export default SubCategory