import React, { useState, useEffect } from "react";
import style from "./Modules.module.css";
import { baseUrl } from "../Url";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ModuleFeatures from "../ModuleFeatures/ModuleFeatures"; // Import the features component
import {
  Modal,
  TextField,
  Paper,
  Box,
  Table,
  Alert,
  Snackbar,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
} from "@mui/material";
import Loader from "../Loader/Loader";


function Modules({ searchQuery, sortOption }) {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [apiDescription, setApiDescription] = useState("");
  const [moduleSuccess, setModuleSuccess] = useState(false);
  const [moduleFail, setModuleFail] = useState(false);
  const [items, setItems] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null);
  const [editModuleData, setEditModuleData] = useState({});
  const [showFeatures, setShowFeatures] = useState(false); // State to control the display of the features component
  const [loading, setLoading] = useState(false);


  const toggleFeatures = (moduleId, moduleCode, moduleName) => {
    console.log("Current showFeatures value (before):", showFeatures);
    const previousModuleId = localStorage.getItem("module_id");

    if (previousModuleId !== moduleId) {
        // If the clicked module is different, reset and set the new module
        setShowFeatures(false);
        setTimeout(() => {
            setShowFeatures(true); // Show the new module's features after resetting
            localStorage.setItem("module_id", moduleId);
            localStorage.setItem("module_code", moduleCode);
            localStorage.setItem("module_name", moduleName);
        }, 0); // Ensure this happens after resetting showFeatures
    } else {
        // If the same module is clicked, simply toggle the current state
        setShowFeatures((prevState) => !prevState);
        localStorage.setItem("module_id", moduleId);
        localStorage.setItem("module_code", moduleCode);
        localStorage.setItem("module_name", moduleName);
    }
};


  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    clearForm();
  };

  const handleOpenEditModal = (module) => {
    setEditModuleData(module);
    setModuleName(module.module_name);
    setDescription(module.description);
    setStatus(module.status);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    clearForm();
  };

  useEffect(() => {
    fetchData();
  }, [searchQuery, sortOption]);

  async function fetchData() {
    setLoading(true);
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/module/sort`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          search: searchQuery,
          sort: sortOption,
        }),
      });
      const data = await response.json();
      setItems(data.data);
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
    setLoading(false);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/module/creation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          module_name: moduleName,
          description: description,
          status: status,
        }),
      });

      const data = await response.json();
      if (data.status === true) {
        setItems([...items, data.data]);
        setModuleSuccess(true);
        setApiDescription("Module added successfully");
        fetchData();
        handleCloseAddModal();
      } else {
        console.error("Error adding module:", data.description);
        setApiDescription(data.description);
        setModuleFail(true);
      }
    } catch (error) {
      console.error("Error adding module:", error);
    }
  }

  async function handleEditSubmit(event) {
    event.preventDefault();

    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/module/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          module_id: editModuleData.module_id,
          module_name: moduleName,
          description: description,
          status: status,
        }),
      });

      const data = await response.json();
      if (data.status === true) {
        const updatedItems = items.map((item) =>
          item.module_id === editModuleData.module_id
            ? { ...item, ...data.data }
            : item
        );
        setItems(updatedItems);
        setModuleSuccess(true);
        setApiDescription("Module updated successfully");
        fetchData();
        handleCloseEditModal();
      } else {
        console.error("Error editing module:", data.description);
        setApiDescription(data.description);
        setModuleFail(true);
      }
    } catch (error) {
      console.error("Error editing module:", error);
    }
  }

  const handleDelete = (moduleId) => {
    setModuleToDelete(moduleId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      const response = await fetch(`${baseUrl}/module/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          module_id: moduleToDelete,
        }),
      });

      const data = await response.json();
      if (data.status === true) {
        const updatedItems = items.filter(
          (item) => item.module_id !== moduleToDelete
        );
        setItems(updatedItems);
        setApiDescription("Module deleted successfully");
        setModuleSuccess(true);
      } else {
        console.error("Error deleting module:", data.description);
        setApiDescription(data.description);
        setModuleFail(true);
      }
    } catch (error) {
      console.error("Error deleting module:", error);
    } finally {
      setModuleToDelete(null);
      setDeleteConfirmationOpen(false);
    }
  };

  const cancelDelete = () => {
    setModuleToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const clearForm = () => {
    setModuleName("");
    setDescription("");
    setStatus("");
  };

  return (
    <>
      <div className={style.snackBar}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={moduleSuccess}
          sx={{ width: "20rem" }}
          autoHideDuration={3000}
          onClose={() => {
            setModuleSuccess(false);
          }}
        >
          <Alert
            severity="success"
            variant="filled"
            sx={{ width: "100%", fontSize: "1.15rem" }}
          >
            {apiDescription}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={moduleFail}
          sx={{ width: "20rem" }}
          autoHideDuration={3000}
          onClose={() => {
            setModuleFail(false);
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            sx={{ width: "100%", fontSize: "1.15rem" }}
          >
            {apiDescription}
          </Alert>
        </Snackbar>
      </div>

      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modalBox}>
          <h2 id="add-module-modal">Add Module</h2>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Module"
              margin="normal"
              variant="outlined"
              required
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              variant="outlined"
              rows={4}
              multiline
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              select
              fullWidth
              label="Status"
              margin="normal"
              variant="outlined"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {["active", "inactive"].map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
            <div className={style.modalActions}>
              <button className={style.modalSub} type="submit">
                Submit
              </button>
              <button
                className={style.modalClose}
                onClick={handleCloseAddModal}
              >
                Close
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modalBox}>
          <h2 id="edit-module-modal">Edit Module</h2>
          <form noValidate autoComplete="off" onSubmit={handleEditSubmit}>
            <TextField
              fullWidth
              label="Module"
              margin="normal"
              variant="outlined"
              required
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              margin="normal"
              variant="outlined"
              rows={4}
              multiline
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              select
              fullWidth
              label="Status"
              margin="normal"
              variant="outlined"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {["active", "inactive"].map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
            <div className={style.modalActions}>
              <button className={style.modalSub} type="submit">
                Update
              </button>
              <button
                className={style.modalClose}
                onClick={handleCloseEditModal}
              >
                Close
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <Modal
        open={deleteConfirmationOpen}
        onClose={cancelDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modalBox}>
          <h2 id="delete-module-modal">Confirm Delete</h2>
          <p>Are you sure you want to delete this module?</p>
          <div className={style.modalActions}>
            <button className={style.delConf} onClick={confirmDelete}>
              Confirm
            </button>
            <button className={style.delClose} onClick={cancelDelete}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.container}>
        <div className={style.containerBox}>
          <div className={style.buttonSection}>
            <button onClick={handleOpenAddModal}>Add Modules</button>
          </div>

          <div className={style.tableBody}>
            <TableContainer className={style.table} component={Paper}>
              {/* <div className={style.tableHeading}>Modules</div> */}

              <Table>
                <TableHead className={style.tableHead}>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Module
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Action
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Features
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => (
                    <TableRow key={item.module_id}>
                      <TableCell>{item.module_name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        <div className={style.actionIcons}>
                          <div className={style.editbtn}>
                            <BiEditAlt
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              title="Edit"
                              onClick={() => handleOpenEditModal(item)}
                            />
                          </div>
                          <div className={style.deletebtn}>
                            <RiDeleteBinLine
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              title="Delete"
                              onClick={() => handleDelete(item.module_id)}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <button
                          className={style.feature}
                          onClick={() =>
                            toggleFeatures(
                              item.module_id,
                              item.module_code,
                              item.module_name
                            )
                          }
                        >
                          Featuresss
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className={style.sideBox}>
          {showFeatures && <ModuleFeatures />}
        </div>
      </div>
       )}
    </>
  );
}

export default Modules;
