import React, { useEffect, useState } from "react";
import style from "./Attribute.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { Alert, Modal, Paper, Snackbar, TextField } from "@mui/material";
import Loader from "../Loader/Loader";

const Attribute = () => {

  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [createName, setCreateName] = useState("");
  const [createDescription, setCreateDescription] = useState("");
  const [attributeData, setAttributeData] = useState([]);
  const [updateName, setUpdateName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deletePopup, setDeletePopup] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [attributeSuccessSnack, setAttributeSuccessSnack] = useState(false);
  const [attributeDeletionSnack, setAttributeDeletionSnack] = useState(false);
  const [attributeUpdationSnack, setAttributeUpdationSnack] = useState(false);
  const [attributeFail, setAttributeFail] = useState(false);
  const [apiDescription, setApiDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [loading, setLoading] = useState(false);

  const createValidation = () => {
    if (!createName) {
        setErrorMessage("Attribute Name is required");
        return false;
    }
    if (!createDescription) {
        setErrorMessage("Description is required");
        return false;
    }
    return true;
}

const updateValidation = () => {
  if (!updateName) {
      setErrorMessage("Attribute Name is required");
      return false;
  }
  if (!updateDescription) {
      setErrorMessage("Description is required");
      return false;
  }

  return true;
};


  async function createAttribute() {
    const reqData = {
      attribute_name: createName,
      description: createDescription
    }
    if (!createValidation()) {
      setValidationFail(true);
      return
  }
  else {
    await fetch(`${baseUrl}/attribute/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'created attribute');
        if (data.status === true) {
          setAttributeSuccessSnack(true);
          listAttribute();
          setCreateName("");
          setCreateDescription("");
        } else {
          setApiDescription(data.description);
          setAttributeFail(true);
        }
      })
    }
  }

  async function listAttribute() {
    setLoading(true);
    await fetch(`${baseUrl}/total/attribute/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({})
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'attribute list');
        if (data.status === true) {
          setAttributeData(data.data);
        }
      })
      .finally(() => setLoading(false)); 
  }

  async function updateAttribute() {
    const reqData = {
      attribute_name: updateName,
      attribute_description: updateDescription,
      attr_id: updateId
    }
    if (!updateValidation()) {
      setValidationFail(true);
      return
  } else {
    await fetch(`${baseUrl}/attribute/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'update data');
        if (data.status === true) {
          setAttributeUpdationSnack(true);
          listAttribute();
          setUpdatePopup(false);
        } else {
          setApiDescription(data.description);
          setAttributeFail(true);
        }
      })
    }
  }

  async function deleteAttribute() {
    await fetch(`${baseUrl}/attribute/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        attr_id: deleteId
      })
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'delete attribute');
        if (data.status === true) {
          setAttributeDeletionSnack(true);
          setDeletePopup(false);
          listAttribute();
        } else {
          setApiDescription(data.description);
          setAttributeFail(true);
        }
      })
  }

  useEffect(() => {
    listAttribute();
  }, [])


  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={attributeSuccessSnack || attributeDeletionSnack || attributeUpdationSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setAttributeSuccessSnack(false);
          setAttributeDeletionSnack(false);
          setAttributeUpdationSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {attributeSuccessSnack
            ? "Attribute created successfully"
            : attributeDeletionSnack
              ? "Attribute deleted successfully"
              : attributeUpdationSnack
                ? "Attribute updated successfully"
                : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={attributeFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setAttributeFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <p>Attribute</p>
      </div>
      <div className={style.attributeBody}>
        <div className={style.bodyLeft}>
          <div className={style.leftHeading}>
            <p>Add New Attribute</p>
          </div>
          <div className={style.leftInputSection}>
            <div className={style.inputContainer}>
              <label>Name</label>
              <input value={createName} onChange={(e) => { 
                    const value = e.target.value;
                    // Replace multiple spaces with a single space
                    if (!/\s{2,}/.test(value)) {
                      setCreateName(value);
                    }
                 }} />
            </div>
            <div className={style.inputContainer}>
              <label>Description</label>
              <textarea value={createDescription} maxLength={100} onChange={(e) => { setCreateDescription(e.target.value) }} />
            </div>
          </div>
          <div className={style.leftButton}>
            <button onClick={() => { createAttribute() }}>Add</button>
          </div>
        </div>
        <div className={style.bodyRight}>
        {loading ? (
              <Loader />
            ) : (
          <div className={style.attributeTable}>
            <div className={style.attributeTableHeader}>
              <div className={style.tableHeadAttribute}>
                <p className={style.attributeTableHeaderText}>Name</p>
              </div>
              <div className={style.tableHeadDescription}>
                <p className={style.attributeTableHeaderText}>Description</p>
              </div>
              
              <div className={style.tableHeadActions}>
                <p className={style.attributeTableHeaderText}>Actions</p>
              </div>
            </div>
            {attributeData.map((item) => (
              <div className={style.attributeTableBody}>
                <div className={style.attributeTableRow}>
                  <div className={style.tableDataAttribute}>
                    <p onClick={() => {
                      navigate("/attribute/type", {
                        state: {
                          attributeValue: { createCode: item.attribute_code, createId: item.attribute_id }
                        }
                      })
                    }} className={style.attributeTableNameText}>{item.attribute_name}</p>
                  </div>
                  <div className={style.tableDataDescription}>
                    <p className={style.attributeTableDataText}> 
                      {
                        item.attribute_description.length > 15
                        ? `${item.attribute_description.substring(0, 15)}...`
                        : item.attribute_description
                      }
                  </p>
                   
                  </div>
                  
                  <div className={style.tableDataActions}>
                    <div className={style.attributeTableDataIcons}>
                      <BiEditAlt
                        onClick={() => {
                          setUpdatePopup(true);
                          setUpdateId(item.attribute_id);
                          setUpdateName(item.attribute_name);
                          setUpdateDescription(item.attribute_description)
                        }}
                      />
                      <RiDeleteBinLine
                        onClick={() => {
                          setDeletePopup(true);
                          setDeleteId(item.attribute_id);
                          setDeleteName(item.attribute_name);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
           )}
        </div>
      </div>

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false);
          }}
          aria-labelledby="add-user-modal"
          aria-describedby="add-user-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-user-modal">Update Attribute</h2>
            <div className={style.modalContent}>
              <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <TextField
                  label="Name"
                  fullWidth
                  value={updateName}
                  onChange={(e) => {
                    setUpdateName(e.target.value);
                  }}
                  margin="normal"
                  style={{ marginRight: "10px" }}
                />
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={updateDescription}
                  onChange={(e) => {
                    setUpdateDescription(e.target.value)
                  }}
                />
              </div>
              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    updateAttribute();
                  }}
                >
                  Submit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}

      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>Are you sure you want to delete {deleteName}?</p>

              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    deleteAttribute();
                  }}
                >
                  Submit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
};

export default Attribute;
