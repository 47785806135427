import React, { useEffect, useState } from 'react';
import style from './CustomerUpdate.module.css'
import { Alert, Checkbox, FormControl, MenuItem, OutlinedInput, Select, Snackbar, TextField } from '@mui/material';
import { baseUrl } from '../Url';
import { useLocation } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Country, State } from 'country-state-city';
import { useNavigate } from "react-router-dom";


function CustomerUpdate() {
    const token = JSON.parse(localStorage.getItem("access-token"));

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    console.log(state);
    const [editId, setEditId] = useState(state.editId);
    const [editName, setEditName] = useState(state.editName);
    const [editEmail, setEditEmail] = useState(state.editEmail);
    const [editPhone, setEditPhone] = useState(state.editPhone);
    const [editBussinessName, setEditBussinessName] = useState(state.editBussinessName);
    const [editLatitude, setEditLatitude] = useState(state.editLatitude);
    const [editLongitude, setEditLongitude] = useState(state.editLongitude);
    const [editLocation, setEditLocation] = useState(state.editLocation);
    const [editAddress1, setEditAddress1] = useState(state.editAddress[0].address1);
    const [editAddress2, setEditAddress2] = useState(state.editAddress[0].address2);
    const [editCity, setEditCity] = useState(state.editCity);
    const [editState, setEditState] = useState(state.editState);
    const [editCountry, setEditCountry] = useState(state.editCountry);
    const [editZip, setEditZip] = useState(state.editZip);
    const [editGender, setEditGender] = useState(state.editGender);
    const [editDob, setEditDob] = useState(state.editDob);
    const [editTaxAcNo, setEditTaxAcNo] = useState(state.editTaxAcNo);
    const [editTaxClass, setEditTaxClass] = useState(state.editTaxClass);
    const [editPriceLevel, setEditPriceLevel] = useState(state.editPriceLevel);
    const [editCustomerGroup, setEditCustomerGroup] = useState(state.editCustomerGroup);
    const [editCustomerType, setEditCustomerType] = useState(state.editCustomerType);
    const [editStatus, setEditStatus] = useState(state.editStatus);
    const [customerGroupData, setCustomerGroupData] = useState([]);
    const [customerUpdationSnack, setCustomerUpdationSnack] = useState(false);
    const [validationFail, setValidationFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [roleFail, setRoleFail] = useState(false);
    const [apiDescription, setApiDescription] = useState("");
    const [segmentData, setSegmentData] = useState([]);
    const [initialValues, setInitialValues] = useState({
            customer_name: editName,
            customer_email: editEmail,
            customer_phone: editPhone,
            bussiness_name: editBussinessName,
            latitude: editLatitude,
            longitude: editLongitude,
            location: editLocation,
            address1: editAddress1, 
            address2: editAddress2,
            city: editCity,
            state: editState,
            country: editCountry,
            zip_code: editZip,
            gender: editGender,
            dob: editDob,
            tax_account_no: editTaxAcNo,
            tax_classification: editTaxClass,
            price_level: editPriceLevel,
            status: editStatus
    });

    const updateValidation = () => {
        if (!editName) {
            setErrorMessage("Name is required");
            return false;
        }
        if (!editEmail) {
            setErrorMessage("Email is required");
            return false;
        }
        if (!editPhone) {
            setErrorMessage("Phone is required");
            return false;
        }
        if (editCustomerType==="B2B" && !editBussinessName) {
            setErrorMessage("Business name is required");
            return false;
        }
        if (editCustomerType==="B2B" && !editLatitude) {
            setErrorMessage("Latitude is required");
            return false;
        }
        if (editCustomerType==="B2B" && !editLongitude) {
            setErrorMessage("Longitude is required");
            return false;
        }
        if (editCustomerType==="B2B" && !editLocation) {
            setErrorMessage("Location is required");
            return false;
        }
        if (editCustomerType==="B2C" && !editZip) {
            setErrorMessage("Zipcode is required");
            return false;
        }
        if (editCustomerType==="B2C" && !editGender) {
            setErrorMessage("Gender is required");
            return false;
        }
        if (!editAddress1) {
            setErrorMessage("Address line 1 is required");
            return false;
        }
        if (!editAddress2) {
            setErrorMessage("Address line 2 is required");
            return false;
        }
        if (!editCountry) {
            setErrorMessage("Country is required");
            return false;
        }
        if (!editState) {
            setErrorMessage("State is required");
            return false;
        }
        if (!editCity) {
            setErrorMessage("City is required");
            return false;
        }
        if (editCustomerType==="B2B" && !editTaxClass) {
            setErrorMessage("Tax classification is required");
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(editEmail)) {
            setErrorMessage("Invalid email format");
            return;
        }
        const phoneRegex = /^\+?[0-9\s-()]+$/;
        if (!phoneRegex.test(editPhone)) {
            setErrorMessage("Invalid phone number");
            return;
        }

        return true;
    };

    const hasChanges = () => {
        return (
            editName !== initialValues.customer_name ||
            editEmail !== initialValues.customer_email ||
            editPhone !== initialValues.customer_phone ||
            editAddress1 !== initialValues.address1 ||
            editAddress2 !== initialValues.address2 ||
            editCountry !== initialValues.country ||
            editState !== initialValues.state ||
            editCity !== initialValues.city ||
            editZip !== initialValues.zip_code ||
            editGender !== initialValues.gender ||
            editStatus !== initialValues.status ||
            editBussinessName !== initialValues.bussiness_name ||
            editLatitude!== initialValues.latitude ||
            editLongitude!== initialValues.longitude ||
            editLocation!== initialValues.location ||
            editTaxClass!== initialValues.tax_classification ||
            editPriceLevel!== initialValues.price_level ||
            editDob !== initialValues.dob ||
            editTaxAcNo !== initialValues.tax_account_no 
        )
    };

    async function segmentList() {
        const reqData = {
          source: "web",
        };
        await fetch(`${baseUrl}/tax/segment/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(reqData),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.status === true) {
              setSegmentData(data.data);
            }
          });
      }

      useEffect(()=>{
          segmentList()
      },[token])

    async function customerUpdation() {
        const reqData = {
            customer_id: editId,
            customer_type: editCustomerType,
            customer_name: editName,
            customer_email: editEmail,
            customer_phone: editPhone,
            bussiness_name: editBussinessName,
            latitude: editLatitude,
            longitude: editLongitude,
            location: editLocation,
            address: [{ address1: editAddress1, address2: editAddress2 }],
            city: editCity,
            state: editState,
            country: editCountry,
            zip_code: editZip,
            gender: editGender,
            dob: editDob,
            tax_account_no: editTaxAcNo,
            tax_classification: editTaxClass,
            price_level: editPriceLevel,
            // customer_group: editCustomerGroup,
            status: editStatus
        }
        if (!updateValidation()) {
            setValidationFail(true);
            return
        } else {
            await fetch(`${baseUrl}/customer/edit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(reqData)
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data, 'updatedData');
                    if (data.status === true) {
                        setCustomerUpdationSnack(true)
                        setTimeout(() => {
                            navigate("/customers/customer");
                        }, 2000);
                    } else {
                        setApiDescription(data.description);
                        setRoleFail(true);
                    }
                })
        }
    }

    useEffect(() => {
        // if (customerUpdatedSuccessfully) { // assuming you have a flag for successful updates
            setInitialValues({
                customer_name: editName,
                customer_email: editEmail,
                customer_phone: editPhone,
                bussiness_name: editBussinessName,
                latitude: editLatitude,
                longitude: editLongitude,
                location: editLocation,
                address1: editAddress1, 
                address2: editAddress2,
                city: editCity,
                state: editState,
                country: editCountry,
                zip_code: editZip,
                gender: editGender,
                dob: editDob,
                tax_account_no: editTaxAcNo,
                tax_classification: editTaxClass,
                price_level: editPriceLevel,
                status: editStatus
            });
        // }
    }, []);

    
    return (
        <div className={style.container}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={customerUpdationSnack}
                sx={{ width: "20rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setCustomerUpdationSnack(false);
                }}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {customerUpdationSnack ? "Customer updated successfully" : ""}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={roleFail}
                sx={{ width: "20rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setRoleFail(false);
                }}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {apiDescription}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={validationFail}
                sx={{ width: "15rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setValidationFail(false);
                }}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className={style.header}>
                <div className={style.heading}>
                    <p>Customer Updation</p>
                </div>
                <div className={style.buttonSection}>
                    <button
                        onClick={() => {
                            customerUpdation()
                        }}
                        disabled={!hasChanges()}
                    >
                        Update Customer
                    </button>
                </div>
            </div>
            <div className={style.customerType}>
                <div className={style.typeLabel}>
                    <p>Customer Type</p>
                    <TextField
                        className={style.label}
                        select
                        value={editCustomerType}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setEditCustomerType(e.target.value)}
                    >
                        <MenuItem value="B2B">B2B</MenuItem>
                        <MenuItem value="B2C">B2C</MenuItem>
                    </TextField>
                </div>
            </div>

            <div className={style.tableSection}>
                {editCustomerType === "B2B" && (
                    <>
                        <div className={style.leftSection}>
                            <div className={style.label}>
                                <p>Contact Person Name</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editName}
                                    onChange={(e) => {
                                        setEditName(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Email</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editEmail}
                                    onChange={(e) => {
                                        setEditEmail(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Phone</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editPhone}
                                    onChange={(e) => {
                                        setEditPhone(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Bussiness Name</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editBussinessName}
                                    onChange={(e) => {
                                        setEditBussinessName(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Latitude</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editLatitude}
                                    // onChange={(e) => {
                                    //     setEditLatitude(e.target.value)
                                    // }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                            setEditLatitude(value);
                                        }
                                      }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Longitude</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    // value={editLongitude}
                                    // onChange={(e) => {
                                    //     setEditLongitude(e.target.value)
                                    // }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                            setEditLongitude(value);
                                        }
                                      }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Location</p>
                                <TextField
                                    className={style.textfield}
                                    value={editLocation}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => setEditLocation(e.target.value)}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Address Line 1</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editAddress1}
                                    onChange={(e) => {
                                        setEditAddress1(e.target.value)
                                    }}
                                />
                            </div>

                        </div>
                        <div className={style.rightSection}>
                            <div className={style.label}>
                                <p>Address Line 2</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editAddress2}
                                    onChange={(e) => {
                                        setEditAddress2(e.target.value)
                                    }}
                                />
                            </div>

                            <div className={style.label}>
                                <p>Country</p>
                                <FormControl
                                    sx={{ mb: 1, mt: 1.5, minWidth: 120 }}
                                    className={style.label}
                                >
                                    <Select
                                        value={editCountry}
                                        onChange={(e) => setEditCountry(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {Country.getAllCountries().map((country) => (
                                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={style.label}>
                                <p>State</p>
                                <FormControl
                                    sx={{ minWidth: 80 }}
                                    className={style.label}
                                >
                                    <Select
                                        value={editState}
                                        onChange={(e) => setEditState(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        disabled={!editCountry}
                                        
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {editCountry &&
                                            State.getStatesOfCountry(editCountry).map((state) => (
                                                <MenuItem key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={style.label}>
                                <p>City</p>
                                <TextField
                                    className={style.textfield}
                                    value={editCity}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => setEditCity(e.target.value)}
                                />
                            </div>

                            <div className={style.label}>
                                <p>Tax Account No:</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editTaxAcNo}
                                    onChange={(e) => {
                                        setEditTaxAcNo(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Tax Classification</p>
                                <TextField
                                     select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editTaxClass}
                                    onChange={(e) => {
                                        setEditTaxClass(e.target.value)
                                    }}
                                    >
                                    {segmentData.map((segment, i) => {
                                        return (
                                            <MenuItem key={i} value={segment.tax_segment_code}>
                                            {segment.tax_segment_name}
                                            </MenuItem>
                                        );
                                        })}
                                    </TextField>
                            </div>
                            <div className={style.label}>
                                <p>Price Level</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editPriceLevel}
                                    onChange={(e) => {
                                        setEditPriceLevel(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Status</p>
                                <TextField
                                     select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editStatus}
                                    onChange={(e) => {
                                        setEditStatus(e.target.value)
                                    }}
                                    >
                                    <MenuItem value="active">Active</MenuItem>
                                    <MenuItem value="inactive">Inactive</MenuItem>
                                    </TextField>
                            </div>
                            
                            
                        </div>
                    </>
                )}

                {editCustomerType === "B2C" && (
                    <>
                        <div className={style.leftSection}>
                            <div className={style.label}>
                                <p>Full Name</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editName}
                                    onChange={(e) => {
                                        setEditName(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Contact Number</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editPhone}
                                    onChange={(e) => {
                                        setEditPhone(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Email</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editEmail}
                                    onChange={(e) => {
                                        setEditEmail(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Address Line 1</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editAddress1}
                                    onChange={(e) => {
                                        setEditAddress1(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Address Line 2</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editAddress2}
                                    onChange={(e) => {
                                        setEditAddress2(e.target.value)
                                    }}
                                />
                            </div>
                            {/* <div className={style.label}>
                                <p>Customer Group</p>
                                <FormControl fullWidth margin="normal">
                                    <Select
                                        multiple
                                        value={editCustomerGroup}
                                        onChange={(e) => setEditCustomerGroup(e.target.value)}
                                        input={<OutlinedInput />}
                                    >

                                        {customerGroupData.map((group) => (
                                            <MenuItem key={group.customergroup_id} value={group.customergroup_id}>
                                                <Checkbox checked={selectedGroup.indexOf(group.customergroup_id) > -1} />
                                                {group.customer_group_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div> */}
                        </div>
                        <div className={style.rightSection}>
                        <div className={style.label}>
                                <p>Country</p>
                                <FormControl
                                    sx={{ mb: 1, mt: 1.5, minWidth: 120 }}
                                    className={style.label}
                                >
                                    <Select
                                        value={editCountry}
                                        onChange={(e)=>{
                                            setEditCountry(e.target.value)
                                        }}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {Country.getAllCountries().map((country) => (
                                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={style.label}>
                                <p>State</p>
                                <FormControl
                                    sx={{ mt: 1, ml: -1.5, minWidth: 120 }}
                                    className={style.label}
                                >
                                    <Select
                                        value={editState}
                                        onChange={(e) => setEditState(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        disabled={!editCountry}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {editCountry &&
                                            State.getStatesOfCountry(editCountry).map((state) => (
                                                <MenuItem key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={style.label}>
                                <p>City</p>
                                <TextField
                                    className={style.textfield}
                                    value={editCity}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => setEditCity(e.target.value)}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Zipcode</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editZip}
                                    onChange={(e) => setEditZip(e.target.value)}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Gender</p>
                                <TextField
                                    select
                                    fullWidth
                                    margin="normal"
                                    value={editGender}
                                    onChange={(e) => {
                                        setEditGender(e.target.value);
                                    }}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </TextField>
                            </div>
                            <div className={style.label}>
                                <p>Status</p>
                                <TextField
                                     select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={editStatus}
                                    onChange={(e) => {
                                        setEditStatus(e.target.value)
                                    }}
                                    >
                                    <MenuItem value="active">Active</MenuItem>
                                    <MenuItem value="inactive">Inactive</MenuItem>
                                    </TextField>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CustomerUpdate