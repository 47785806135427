import React, { useState } from "react";
import style from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { baseUrl } from "../Url";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setErrorMessage("Please provide both username and password.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/adminpanel/login`, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("access-token", JSON.stringify(data.data[0].token));
        localStorage.setItem("adminUserId", data.data[0].user_id);
        localStorage.setItem("userName", data.data[0].user_name)
        localStorage.setItem("adminStatus", data.data[0].is_superadmin);
        localStorage.setItem("role",data.data[0].role[0].role_name);
        localStorage.setItem("roleId",data.data[0].role[0].role_id);
        localStorage.setItem("roleCode",data.data[0].role[0].role_code);
        localStorage.setItem("organizationId",data.data[0].org_id);
        if (data.data[0].customer === true ) {
          setErrorMessage("You are not authorized to access this page");
        } else {
          navigate("/hub");
        }
      } else {
        setErrorMessage("Invalid Credentials");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("An error occurred while logging in.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.loginContainer}>
        <div className={style.loginSection}>
          <div className={style.loginLeft}>
            {/* <div className={style.logo}>
              <img src={logo} alt="" />
            </div> */}
          </div>
          <div className={style.loginRight}>
            <div className={style.rightHeaderContent}>
              <p>
                Welcome back to <br />
                TeaMan
              </p>
            </div>
            <div className={style.signInSection}>
              <div className={style.signInTop}>
                <h6>Sign In</h6>
                <p>Enter your username and password to sign in!</p>
              </div>
              <div className={style.inputSection}>
                <div className={style.inputValues}>
                  <label htmlFor="email">
                    Username <span>*</span>
                  </label>
                  <input
                    className={style.input}
                    type="text"
                    value={username}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setErrorMessage("");
                    }}
                  />
                </div>

                <div className={style.inputValues}>
                  <label htmlFor="password">
                    Password <span>*</span>
                  </label>

                  <div className={style.passwordInput}>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                    {showPassword ? (
                      <AiOutlineEye onClick={togglePasswordVisibility} />
                    ) : (
                      <AiOutlineEyeInvisible
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                <div className={style.errorMsg}>
                  {" "}
                  {errorMessage && (
                    <p className={style.error}>{errorMessage}</p>
                  )}
                </div>
              </div>

              <div className={style.secondInputSection}>
                <div className={style.secondInputLeft}>
                  <input type="checkbox" name="" id="" />
                  <p>Keep me logged in</p>
                </div>
                <div className={style.secondInputRight}>
                  <p>Forget password?</p>
                </div>
              </div>
              <div className={style.signInButton}>
                <button onClick={handleLogin} disabled={isLoading}>
                  {isLoading ? "Logging in..." : "Continue"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
