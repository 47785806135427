import React, { useState, useEffect, useCallback } from "react";
import style from "./TaxItem.module.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Alert,
  Snackbar,
  OutlinedInput,
} from "@mui/material";
import { baseUrl } from "../Url";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import Loader from "../Loader/Loader";

const initialItemState = {
  tax_item_id: "",
  tax_item_name: "",
  tax_item_description: "",
  tax_percentage: "",
  tax_group_code: "",
  tax_item_status: "",
};

const TaxItem = () => {
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [itemStatus, setItemStatus] = useState("");
  const [itemPercentage, setItemPercentage] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [itemData, setItemData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [initialItem, setInitialItem] = useState(initialItemState);
  const [editItem, setEditItem] = useState(initialItemState);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [itemId, setItemId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const createValidation = () => {
    if (!itemName) {
      setErrorMessage("Item name is required");
      return false;
    }
    if (!itemDesc) {
      setErrorMessage("Item description is required");
      return false;
    }
    if (!itemPercentage) {
      setErrorMessage("Item percentage is required");
      return false;
    }
    if (!selectedGroup) {
      setErrorMessage("Item group is required");
      return false;
    }
    if (!itemStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  async function GroupList() {
    const reqData = {
      source: "web",
    };
    await fetch(`${baseUrl}/tax/group/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setGroupData(data.data);
        }
      });
  }

  async function createItem() {
    const reqData = {
      tax_item_name: itemName,
      tax_item_description: itemDesc,
      tax_percentage: itemPercentage,
      tax_group_code: selectedGroup,
      tax_item_status: itemStatus,
    };
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/tax/item/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(reqData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            setCreatePopup(false);
            itemList();
            setCreateSuccess(true);
            setItemName("");
            setItemDesc("");
            setItemPercentage("");
            setItemStatus("");
            setSelectedGroup("");
          } else {
            setValidationFail(true);
            setErrorMessage(data.description);
          }
        });
    }
  }

  async function itemList(searchValue) {
    const reqData = {
      source: "web",
      search_value: searchValue ? searchValue : "",
    };
    setLoading(true);
    await fetch(`${baseUrl}/tax/item/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true && data.data.length > 0) {
          setItemData(data.data);
        } else {
          setItemData([]);
        }
      })
      .finally(() => setLoading(false));
  }

  const checkForChanges = useCallback(() => {
    const hasChanges = Object.keys(initialItem).some(
      (key) =>
        String(editItem[key] || "").trim() !==
        String(initialItem[key] || "").trim()
    );
    setIsEdited(hasChanges);
  }, [editItem, initialItem]);

  useEffect(() => {
    checkForChanges();
  }, [editItem, checkForChanges]);

  async function updateItem() {
    if (isEdited) {
      await fetch(`${baseUrl}/tax/item/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editItem),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            setEditPopup(false);
            itemList();
            setUpdateSuccess(true);
          } else {
            setValidationFail(true);
            setErrorMessage(data.description);
          }
        });
    } else {
      setValidationFail(true);
      setErrorMessage("No changes found");
    }
  }

  async function deleteItem() {
    const reqData = {
      tax_item_id: itemId,
    };
    await fetch(`${baseUrl}/tax/item/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          itemList();
          setDeleteSuccess(true);
        }
      });
  }

  useEffect(() => {
    GroupList();
    itemList();
  }, [token]);

  useEffect(() => {
    if (searchValue !== "") {
      itemList(searchValue);
    } else {
      itemList("");
    }
  }, [searchValue]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess || updateSuccess || deleteSuccess}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
          setUpdateSuccess(false);
          setDeleteSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "Item created successfully"
            : updateSuccess
            ? "Item updated successfully"
            : deleteSuccess
            ? "Item deleted successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Tax Items</p>
        </div>
        <div className={style.headerAction}>
          <div className={style.search}>
            <IoSearch className={style.searchIcon} />
            <input
              type="text"
              placeholder=""
              className={style.searchInput}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
          <div className={style.addButton}>
            <button
              onClick={() => {
                setCreatePopup(true);
              }}
            >
              Add Tax item
            </button>
          </div>
        </div>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.body}>
        <div className={style.bodySection}>
          <div className={style.listTableHeader}>
            <div className={style.listTableHeadName}>
              <p className={style.listTableHeaderText}>Name</p>
            </div>
            <div className={style.listTableDescription}>
              <p className={style.listTableHeaderText}>Description</p>
            </div>
            <div className={style.listTablePercentage}>
              <p className={style.listTableHeaderText}>Tax %</p>
            </div>
            <div className={style.listTableGroup}>
              <p className={style.listTableHeaderText}>Group</p>
            </div>
            <div className={style.listTableStatus}>
              <p className={style.listTableHeaderText}>Status</p>
            </div>
            <div className={style.listTableHeadActions}>
              <p className={style.listTableHeaderText}>Actions</p>
            </div>
          </div>

          {itemData.length > 0 ? (
            <div className={style.listTableBody}>
              {itemData.map((item, i) => (
                <div key={i} className={style.listTableRow}>
                  {/* Table rows for each item */}
                  <div className={style.listTableDataName}>
                    <p className={style.listTableDataText}>
                      {item.tax_item_name}
                    </p>
                  </div>
                  <div className={style.listTableDataDescription}>
                    <p className={style.listTableDataText}>
                      {item.tax_item_description}
                    </p>
                  </div>
                  <div className={style.listTableDataPercentage}>
                    <p className={style.listTableDataText}>
                      {item.tax_percentage}
                    </p>
                  </div>
                  <div className={style.listTableDataGroup}>
                    <p className={style.listTableDataText}>
                      {item.tax_group_code}
                    </p>
                  </div>
                  <div className={style.listTableDataStatus}>
                    {item.tax_item_status === "active" ? (
                      <FaRegCircleCheck
                        style={{ color: "green", fontSize: "1.35rem" }}
                      />
                    ) : item.tax_item_status === "inactive" ? (
                      <IoIosCloseCircleOutline
                        style={{ color: "red", fontSize: "1.5rem" }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={style.listTableDataActions}>
                    <div className={style.listTableDataIcons}>
                      <BiEditAlt
                        onClick={() => {
                          setEditPopup(true);
                          setEditItem(item);
                          setInitialItem(item);
                        }}
                      />
                      <RiDeleteBinLine
                        onClick={() => {
                          setDeletePopup(true);
                          setItemId(item.tax_item_id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Alert severity="error" color="warning">
              No tax item found..
            </Alert>
          )}
        </div>
      </div>
       )}

      {createPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Create Tax Item</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setCreatePopup(false);
                    setItemName("");
                    setItemDesc("");
                    setItemPercentage("");
                    setItemStatus("");
                    setSelectedGroup("");
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={itemName}
                onChange={(e) => {
                  setItemName(e.target.value);
                }}
                label="Tax item name"
                fullWidth
                margin="normal"
              />

              <TextField
                value={itemDesc}
                onChange={(e) => {
                  setItemDesc(e.target.value);
                }}
                label="Tax item description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={itemPercentage}
                onChange={(e) => {
                  setItemPercentage(e.target.value);
                }}
                label="Tax item percentage"
                fullWidth
                margin="normal"
              />
              <TextField
                value={itemStatus}
                onChange={(e) => {
                  setItemStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
              <TextField
                select
                label="Select Group"
                fullWidth
                margin="normal"
                value={selectedGroup}
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                }}
              >
                {groupData.map((group, i) => {
                  return (
                    <MenuItem key={i} value={group.tax_group_code}>
                      {group.tax_group_name}
                    </MenuItem>
                  );
                })}
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    createItem();
                  }}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                    setItemName("");
                    setItemDesc("");
                    setItemPercentage("");
                    setItemStatus("");
                    setSelectedGroup("");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/*Edit Popup */}
      {editPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Edit Tax Item</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setEditPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={editItem.tax_item_name}
                onChange={(e) => {
                  setEditItem({
                    ...editItem,
                    tax_item_name: e.target.value,
                  });
                }}
                label="Tax group name"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editItem.tax_item_description}
                onChange={(e) => {
                  setEditItem({
                    ...editItem,
                    tax_item_description: e.target.value,
                  });
                }}
                label="Tax group description"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editItem.tax_percentage}
                onChange={(e) => {
                  setEditItem({
                    ...editItem,
                    tax_percentage: e.target.value,
                  });
                }}
                label="Tax group percentage"
                fullWidth
                margin="normal"
              />
              <TextField
                value={editItem.tax_item_status}
                onChange={(e) => {
                  setEditItem({
                    ...editItem,
                    tax_item_status: e.target.value,
                  });
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
              <TextField
                select
                label="Select Group"
                fullWidth
                margin="normal"
                value={editItem.tax_group_code}
                onChange={(e) => {
                  setEditItem({
                    ...editItem,
                    tax_group_code: e.target.value,
                  });
                }}
              >
                {groupData.map((group, i) => {
                  return (
                    <MenuItem key={i} value={group.tax_group_code}>
                      {group.tax_group_name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    updateItem();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setEditPopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {/* Delete popup*/}
      {deletePopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.deletePopup}>
            <div className={style.deletePopupHeader}>
              <div className={style.deletePopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setDeletePopup(false);
                    setItemId("");
                  }}
                />
              </div>
            </div>
            <div className={style.deletePopupSection}>
              <div className={style.deletePopupText}>
                <p>Are you sure want to delete?</p>
              </div>
              <div className={style.deletePopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => {
                    deleteItem();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.closeButton}
                  onClick={() => {
                    setDeletePopup(false);
                    setItemId("");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TaxItem;
