import { React, useState, useEffect } from "react";
import style from "./User.module.css";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { baseUrl } from "../Url";
import { IoSearch } from "react-icons/io5";
import { useOutletContext } from "react-router-dom";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Loader from "../Loader/Loader";

function User() {
  const { selectedNewHub } = useOutletContext();

  const [createPopup, setCreatePopup] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [userData, setUserData] = useState([]);
  const [modalOrgId, setModalOrgId] = useState("");
  const [userView, setUserView] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [userSuccessSnack, setUserSuccessSnack] = useState(false);
  const [editId, setEditId] = useState("");
  const [editFirstname, setEditFirstname] = useState("");
  const [editLastname, setEditLastname] = useState("");
  const [editContact, setEditContact] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editUsername, setEditUsername] = useState("");
  const [editStatus, setEditStatus] = useState("Active");
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [apiDescription, setApiDescription] = useState("");
  const [userFail, setUserFail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [editRole, setEditRole] = useState("");
  const [editSelectedRoleID, setEditSelectedRoleID] = useState("");
  const [loading, setLoading] = useState(false);

  const validateNumber = (value) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      return true;
    }
  };

  const createValidation = () => {
    if (!firstname) {
      setErrorMessage("First name is required");
      return false;
    }
    if (!lastname) {
      setErrorMessage("Last name is required");
      return false;
    }
    if (!username) {
      setErrorMessage("Username is required");
      return false;
    }
    if (!password) {
      setErrorMessage("Password is required");
      return false;
    }
    if (!email) {
      setErrorMessage("Email is required");
      return false;
    }
    if (!phone) {
      setErrorMessage("Phone is required");
      return false;
    }

    if (!selectedRoleId) {
      setErrorMessage("Role is required");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Invalid email format");
      return;
    }
    const phoneRegex = /^\+?[0-9\s-()]+$/;
    if (!phoneRegex.test(phone)) {
      setErrorMessage("Invalid phone number");
      return;
    }

    return true;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const updateValidation = () => {
    if (!editFirstname) {
      setErrorMessage("First name is required");
      return false;
    }
    if (!editLastname) {
      setErrorMessage("Last name is required");
      return false;
    }
    if (!editContact) {
      setErrorMessage("Phone is required");
      return false;
    }
    if (!editUsername) {
      setErrorMessage("Username is required");
      return false;
    }
    if (!editPassword) {
      setErrorMessage("Password is required");
      return false;
    }
    if (!editEmail) {
      setErrorMessage("Email is required");
      return false;
    }
    const phoneRegex = /^\+?[0-9\s-()]+$/;
    if (!phoneRegex.test(editContact)) {
      setErrorMessage("Invalid phone number");
      return;
    }

    return true;
  };

  const handleRoleChange = (event) => {
    const roles = event.target.value;
    setSelectedRoleId(roles);
    const selectedRole = roleData.find((role) => role.role_id === roles);
    const sortedRole = [
      {
        role_id: selectedRole.role_id,
        role_name: selectedRole.role_name,
        role_code: selectedRole.role_code,
        description: selectedRole.description,
      },
    ];
    setRole(sortedRole);
  };
  const handleEditRoleChange = (event) => {
    const roles = event.target.value;
    setEditSelectedRoleID(roles);
    const selectedRole = roleData.find((role) => role.role_id === roles);
    const sortedRole = [
      {
        role_id: selectedRole.role_id,
        role_name: selectedRole.role_name,
        role_code: selectedRole.role_code,
        description: selectedRole.description,
      },
    ];
    setEditRole(sortedRole);
  };

  const handleUserCreation = () => {
    const adminUserId = localStorage.getItem("adminUserId");
    if (!createValidation()) {
      setValidationFail(true);
      // setCreatePopup(true);
      return;
    }
    const userData = {
      firstname: firstname,
      lastname: lastname,
      username: username,
      password: password,
      email: email,
      contactNumber: phone,
      role: role,
      org_id: selectedNewHub,
      created_by: adminUserId,
    };
    console.log(userData, "userData");
    userCreation(userData);
    userListing(roleId, selectedNewHub);
    // setCreatePopup(false);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  async function roleListing() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/roles/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "roledata");
        setRoleData(data.data);
        setRoleId("All");
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  }

  async function userCreation(userData) {
    if (!createValidation()) {
      setValidationFail(true);
      return;
    } else {
      var storedToken = JSON.parse(localStorage.getItem("access-token"));
      await fetch(`${baseUrl}/signup`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "data");
          if (data.status === true) {
            userListing(roleId, selectedNewHub);
            setCreatePopup(false);
            setUserSuccessSnack(true);
            setFirstname("");
            setLastname("");
            setUsername("");
            setPassword("");
            setEmail("");
            setPhone("");
            // setRole("");
            setModalOrgId("");
            setSelectedRoleId("");
          } else {
            setCreatePopup(true);
            setApiDescription(data.description);
            setUserFail(true);
          }
        });
    }
  }

  async function userListing(roleId, orgId, searchValue) {
    const reqData = {
      org_id: orgId ? orgId : "All",
      role: roleId ? roleId : "All",
      search: searchValue ? searchValue : "",
    };

    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    setLoading(true);
    await fetch(`${baseUrl}/users_list/search/based_on_org_role`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userListing");
        if (data.status === true) {
          setUserData(data.data);
          if (data.data.length > 0) {
            setUserView(true);
          } else {
            setUserView(false);
          }
        } else {
          setUserView(false);
        }
      })
      .finally(() => setLoading(false));
  }

  async function updateUser() {
    const reqData = {
      user_id: editId,
      firstname: editFirstname,
      lastname: editLastname,
      contactNumber: editContact,
      username: editUsername,
      password: editPassword,
      email: editEmail,
      role: editRole,
      status: editStatus,
    };
    if (!updateValidation()) {
      setValidationFail(true);
      return;
    } else {
      const storedToken = JSON.parse(localStorage.getItem("access-token"));
      await fetch(`${baseUrl}/user/edit`, {
        method: "POST",
        body: JSON.stringify(reqData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "usereditdata");
          if (data.status === true) {
            setEditFirstname("");
            setEditLastname("");
            setEditContact("");
            setEditUsername("");
            setEditPassword("");
            setEditEmail("");
            setUpdatePopup(false);
            userListing(roleId, selectedNewHub);
          } else {
            setApiDescription(data.description);
            setUserFail(true);
          }
        });
    }
  }

  async function deleteUser() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/user/delete`, {
      method: "POST",
      body: JSON.stringify({
        user_id: deleteId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          userListing(roleId, selectedNewHub);
          setDeletePopup(false);
        }
      });
  }

  useEffect(() => {
    roleListing();
  }, []);
  useEffect(() => {
    setRoleId("");
    if (selectedNewHub !== "") {
      userListing("", selectedNewHub);
    }
  }, [selectedNewHub]);

  useEffect(() => {
    console.log(editRole);
  }, [editRole]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={userSuccessSnack}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setUserSuccessSnack(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {userSuccessSnack ? "User created successfully" : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={userFail}
        sx={{ width: "20rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setUserFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiDescription}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>User Management</p>
        </div>
        <div className={style.dropdown}>
          <Box sx={{ minWidth: 200 }}>
            <FormControl sx={{ mr: 1, minWidth: 160 }} size="small">
              <Select
                value={roleId}
                onChange={(e) => {
                  const selectedRoleId = e.target.value;
                  console.log(selectedRoleId, "selectedRoleId");
                  setRoleId(selectedRoleId);
                  if (selectedRoleId === "All") {
                    userListing(null, selectedNewHub);
                  } else {
                    userListing(selectedRoleId, selectedNewHub);
                  }
                }}
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&fieldset:hover ": {
                        borderColor: "#794a57",
                      },
                      "& fieldset:active": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  {" "}
                  <em>Filter By Role</em>
                </MenuItem>
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                {roleData.map((item) => (
                  <MenuItem key={item.role_name} value={item.role_name}>
                    {item.role_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className={style.search}>
          <IoSearch className={style.searchIcon} />
          <input
            type="text"
            placeholder="search"
            className={style.searchInput}
            value={searchValue}
            onChange={(e) => {
              const searchInputValue = e.target.value;
              setSearchValue(searchInputValue);
              if (searchInputValue !== "") {
                userListing(roleId, selectedNewHub, searchInputValue);
              } else {
                userListing(roleId, selectedNewHub);
                setUserView(true);
              }
            }}
          />
        </div>
        <button
          onClick={() => {
            setCreatePopup(true);
          }}
          className={style.buttonSection}
        >
          Add User
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={style.tableSection}>
          {userView && selectedNewHub !== "" ? (
            <TableContainer className={style.table} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead className={style.tableHead}>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Username
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Created Date
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0.01em",
                        textAlign: "left",
                        color: "#000",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData.map((item) => (
                    <TableRow key={item.username}>
                      <TableCell component="th" scope="row">
                        {item.username}
                      </TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">
                        {(() => {
                          const date = new Date(item.created_date.$date);
                          const day = String(date.getDate()).padStart(2, "0");
                          const month = String(date.getMonth() + 1).padStart(
                            2,
                            "0"
                          ); // Months are zero-based
                          const year = date.getFullYear();
                          return `${day}-${month}-${year}`;
                        })()}
                      </TableCell>
                      <TableCell align="left">
                        {item.role[0]?.role_name}
                      </TableCell>
                      <TableCell align="left">
                        <BiEditAlt
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          title="Edit"
                          onClick={() => {
                            setUpdatePopup(true);
                            setEditId(item.user_id);
                            setEditFirstname(item.firstname);
                            setEditLastname(item.lastname);
                            setEditContact(item.contactNumber);
                            setEditUsername(item.username);
                            setEditPassword(item.password);
                            setEditEmail(item.email);
                            setEditStatus(item.status);
                            setEditSelectedRoleID(item.role[0]?.role_id);
                            if (item.role[0]?.role_id) {
                              const selectedRole = roleData.find(
                                (role) => role.role_id === item.role[0]?.role_id
                              );
                              const sortedRole = [
                                {
                                  role_id: selectedRole.role_id,
                                  role_name: selectedRole.role_name,
                                  role_code: selectedRole.role_code,
                                  description: selectedRole.description,
                                },
                              ];
                              setEditRole(sortedRole);
                            }
                          }}
                        />
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            marginLeft: "15px",
                          }}
                          title="Delete"
                          onClick={() => {
                            setDeletePopup(true);
                            setDeleteId(item.user_id);
                            setDeleteName(item.username);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : userView === true && selectedNewHub === "" ? (
            <Alert severity="error" color="warning">
              Please select a hub to view users.
            </Alert>
          ) : (
            <Alert severity="error" color="warning">
              No user found..!
            </Alert>
          )}
        </div>
      )}

      {/* {createPopup && ( */}
      <Modal
        open={createPopup}
        onClose={() => {
          setCreatePopup(false);
        }}
        aria-labelledby="add-user-modal"
        aria-describedby="add-user-form"
      >
        <Paper className={style.usermodal} elevation={3}>
          <h2 id="add-user-modal">Add User</h2>
          <div className={style.modalContent}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="First Name"
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                fullWidth
                margin="normal"
                style={{ marginRight: "10px" }}
              />
              <TextField
                label="Last Name"
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                fullWidth
                margin="normal"
              />
            </div>
            <TextField
              label="Username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              value={phone}
              // onChange={(e) => {
              //   setPhone(e.target.value);
              // }}
              onChange={(e) => {
                if (validateNumber(e.target.value)) {
                  setPhone(e.target.value);
                }
              }}
              fullWidth
              margin="normal"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <FormControl fullWidth margin="normal">
                <InputLabel id="role-select-label">Select Role</InputLabel>
                <Select
                  label="Select Role"
                  labelId="role-select-label"
                  id="role-select"
                  value={selectedRoleId}
                  MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                  onChange={handleRoleChange}
                >
                  {roleData.map((item) => (
                    <MenuItem key={item.role_id} value={item.role_id}>
                      {item.role_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={style.modalActions}>
              <button
                className={style.modalSub}
                variant="contained"
                onClick={() => {
                  handleUserCreation();
                }}
              >
                Submit
              </button>
              <button
                className={style.modalClose}
                variant="outlined"
                onClick={() => {
                  setCreatePopup(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Paper>
      </Modal>
      {/* )} */}

      {updatePopup && (
        <Modal
          open={updatePopup}
          onClose={() => {
            setUpdatePopup(false);
          }}
          aria-labelledby="add-user-modal"
          aria-describedby="add-user-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="add-user-modal">Update User</h2>
            <div className={style.modalContent}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  label="First Name"
                  fullWidth
                  value={editFirstname}
                  onChange={(e) => {
                    setEditFirstname(e.target.value);
                  }}
                  margin="normal"
                  style={{ marginRight: "10px" }}
                />
                <TextField
                  label="Last Name"
                  fullWidth
                  value={editLastname}
                  onChange={(e) => {
                    setEditLastname(e.target.value);
                  }}
                  margin="normal"
                />
              </div>
              <TextField
                label="Username"
                fullWidth
                value={editUsername}
                onChange={(e) => {
                  setEditUsername(e.target.value);
                }}
                margin="normal"
              />
              <TextField
                label="Password"
                fullWidth
                value={editPassword}
                onChange={(e) => {
                  setEditPassword(e.target.value);
                }}
                margin="normal"
              />
              <TextField
                label="Email"
                fullWidth
                value={editEmail}
                onChange={(e) => {
                  setEditEmail(e.target.value);
                }}
                margin="normal"
              />
              <TextField
                label="Phone"
                fullWidth
                value={editContact}
                onChange={(e) => {
                  setEditContact(e.target.value);
                }}
                margin="normal"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <FormControl fullWidth margin="normal">
                  <InputLabel id="role-select-label">Select Role</InputLabel>
                  <Select
                    label="Select Role"
                    labelId="role-select-label"
                    id="role-select"
                    value={editSelectedRoleID}
                    MenuProps={{ PaperProps: { style: { maxHeight: 150 } } }}
                    onChange={handleEditRoleChange}
                  >
                    {roleData.map((item) => (
                      <MenuItem key={item.role_id} value={item.role_id}>
                        {item.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="org-select-label">Status</InputLabel>
                  <Select
                    labelId="org-select-label"
                    label="Status"
                    id="org-select"
                    value={editStatus}
                    onChange={(e) => {
                      setEditStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    updateUser();
                  }}
                >
                  SUbmit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setUpdatePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
      {deletePopup && (
        <Modal
          open={deletePopup}
          onClose={() => {
            setDeletePopup(false);
          }}
          aria-labelledby="delete-hub-modal"
          aria-describedby="delete-hub-form"
        >
          <Paper className={style.modal} elevation={3}>
            <h2 id="delete-hub-modal">Confirm Delete</h2>
            <div className={style.modalContent}>
              <p className={style.deleteContent}>
                Are you sure you want to delete {deleteName}?
              </p>

              <div className={style.modalActions}>
                <button
                  className={style.modalSub}
                  variant="contained"
                  onClick={() => {
                    deleteUser();
                  }}
                >
                  SUbmit
                </button>
                <button
                  className={style.modalClose}
                  variant="outlined"
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default User;
