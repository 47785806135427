import React, { useState, useEffect } from "react";
import style from "./GoDown.module.css";
import {
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Snackbar,
} from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { useOutletContext } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import Loader from "../Loader/Loader";

const GoDown = () => {
  const { selectedNewHub } = useOutletContext();

  const token = JSON.parse(localStorage.getItem("access-token"));

  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [godownName, setGoDownName] = useState("");
  const [godownDesc, setGoDownDesc] = useState("");
  const [status, setStatus] = useState("");
  const [isTransportable, setIsTransportable] = useState("");
  const [parentGodown, setParentGodown] = useState("");
  const [createSuccess, setCreateSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [godownData, setGodownData] = useState([]);
  const [godownID, setGodownID] = useState("");
  const [editGodownName, setEditGoDownName] = useState("");
  const [editGodownDesc, setEditGoDownDesc] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [editIsTransportable, setEditIsTransportable] = useState("");
  const [editParentGodown, setEditParentGodown] = useState("");
  const [validationFail, setValidationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateFields = () => {
    if (!godownName) {
      setErrorMessage("Godown Name is required");
      return false;
    }
    if (!godownDesc) {
      setErrorMessage("Description is required");
      return false;
    }
    if (isTransportable === "") {
      setErrorMessage("Transportable status is required");
      return false;
    }
    if (!status) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  const validateEditFields = () => {
    if (!editGodownName) {
      setErrorMessage("Godown Name is required");
      return false;
    }
    if (!editGodownDesc) {
      setErrorMessage("Description is required");
      return false;
    }
    if (editIsTransportable === "") {
      setErrorMessage("Transportable status is required");
      return false;
    }
    if (!editStatus) {
      setErrorMessage("Status is required");
      return false;
    }
    return true;
  };

  async function godownList() {
    const reqData = {
      hub_id: selectedNewHub,
    };
    setLoading(true);
    await fetch(`${baseUrl}/list/godown`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setGodownData(data.data);
        }
      })
      .finally(() => setLoading(false)); 
  }

  async function godownCreate() {
    if (!validateFields()) {
      setValidationFail(true);
      return;
    }
    const reqData = {
      hub_id: selectedNewHub,
      parent_id: parentGodown,
      godown_name: godownName,
      description: godownDesc,
      is_transportable: isTransportable === "true" ? true : false,
      status: status,
    };
    await fetch(`${baseUrl}/create/godown`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setCreatePopup(false);
          setCreateSuccess(true);
          godownList();
          setGoDownName("");
          setGoDownDesc("");
          setIsTransportable("");
          setParentGodown("");
          setStatus("");
        }
      });
  }

  async function godownUpdate() {
    if (!validateEditFields()) {
      setValidationFail(true);
      return;
    }
    const reqData = {
      godown_id: godownID,
      hub_id: selectedNewHub,
      parent_id: editParentGodown,
      godown_name: editGodownName,
      description: editGodownDesc,
      is_transportable: editIsTransportable === "true" ? true : false,
      status: editStatus,
    };
    await fetch(`${baseUrl}/update/godown`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setEditPopup(false);
          setEditSuccess(true);
          godownList();
          setEditGoDownName("");
          setEditGoDownDesc("");
          setEditIsTransportable("");
          setEditStatus("");
        }
      });
  }

  async function godownDelete() {
    const reqData = {
      godown_id: godownID,
    };
    await fetch(`${baseUrl}/delete/godown`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setDeletePopup(false);
          setDeleteSuccess(true);
          setGodownID("");
          godownList();
        }
      });
  }

  useEffect(() => {
    if (selectedNewHub) {
      godownList();
    }
  }, [selectedNewHub]);

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={createSuccess || deleteSuccess || editSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCreateSuccess(false);
          setDeleteSuccess(false);
          setEditSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {createSuccess
            ? "GoDown created successfully"
            : editSuccess
            ? "GoDown Updated successfully"
            : deleteSuccess
            ? "GoDown deleted successfully"
            : ""}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationFail}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setValidationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Godown</p>
        </div>
        <div className={style.headerActions}>
          <button
            onClick={() => {
              setCreatePopup(true);
            }}
          >
            Add Godown
          </button>
        </div>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.body}>
        <TableContainer className={style.table} component={Paper}>
          <Table aria-label="caption table">
            <TableHead className={style.tableHead}>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Godown Number
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Godown name
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Transportable
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {godownData && godownData.length > 0 ? (
                godownData.map((data, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="left">{data.godown_code}</TableCell>
                      <TableCell align="left">{data.godown_name}</TableCell>
                      <TableCell align="left">{data.description}</TableCell>
                      <TableCell align="left">
                        {data.is_transportable ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left">{data.status}</TableCell>

                      <TableCell align="left">
                        <BiEditAlt
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          title="Edit"
                          onClick={() => {
                            setEditPopup(true);
                            setGodownID(data.godown_id);
                            setEditGoDownDesc(data.description);
                            setEditGoDownName(data.godown_name);
                            setEditIsTransportable(
                              data.is_transportable === true ? "true" : "false"
                            );
                            setEditStatus(data.status);
                            setEditParentGodown(data.parent_id);
                          }}
                        />
                        <RiDeleteBinLine
                          style={{
                            fontSize: "20px",
                            marginLeft: "15px",
                            cursor: "pointer",
                          }}
                          title="Delete"
                          onClick={() => {
                            setDeletePopup(true);
                            setGodownID(data.godown_id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
       )}
      {createPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Create GoDown</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setCreatePopup(false);
                    setGoDownName("");
                    setGoDownDesc("");
                    setIsTransportable("");
                    setParentGodown("");
                    setStatus("");
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={godownName}
                onChange={(e) => {
                  setGoDownName(e.target.value);
                }}
                label="Godown name"
                fullWidth
                margin="normal"
              />

              <TextField
                value={godownDesc}
                onChange={(e) => {
                  setGoDownDesc(e.target.value);
                }}
                label="Description"
                fullWidth
                margin="normal"
              />
              <TextField
                select
                label="Select Transportable"
                fullWidth
                margin="normal"
                value={isTransportable}
                onChange={(e) => {
                  setIsTransportable(e.target.value);
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              {godownData.length > 0 ? (
                <TextField
                  select
                  label="Select Parent Godown"
                  fullWidth
                  margin="normal"
                  value={parentGodown}
                  onChange={(e) => {
                    setParentGodown(e.target.value);
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Backspace' && parentGodown) {
                  //     setParentGodown(''); // Clear the selection if backspace is pressed
                  //   }
                  // }}
                >
                  <MenuItem value="">None</MenuItem>
                  {godownData.length > 0 ? (
                    godownData.map((data) => {
                      return (
                        <MenuItem key={data.godown_id} value={data.godown_id}>
                          {data.godown_name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </TextField>
              ) : (
                <></>
              )}

              <TextField
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => godownCreate()}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setCreatePopup(false);
                    setGoDownName("");
                    setGoDownDesc("");
                    setIsTransportable("");
                    setParentGodown("");
                    setStatus("");
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {editPopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.createPopupPopup}>
            <div className={style.createPopupHeader}>
              <div className={style.createPopupHeaderText}>
                {" "}
                <p>Update GoDown</p>
              </div>
              <div className={style.createPopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setEditPopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.createPopupSection}>
              <TextField
                value={editGodownName}
                onChange={(e) => {
                  setEditGoDownName(e.target.value);
                }}
                label="Godown name"
                fullWidth
                margin="normal"
              />

              <TextField
                value={editGodownDesc}
                onChange={(e) => {
                  setEditGoDownDesc(e.target.value);
                }}
                label="Description"
                fullWidth
                margin="normal"
              />
              <TextField
                select
                label="Select Transportable"
                fullWidth
                margin="normal"
                value={editIsTransportable}
                onChange={(e) => {
                  setEditIsTransportable(e.target.value);
                }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              {godownData.length > 0 ? (
                <TextField
                  select
                  label="Select Parent Godown"
                  fullWidth
                  margin="normal"
                  value={editParentGodown}
                  onChange={(e) => {
                    setEditParentGodown(e.target.value);
                  }}
                >
                  {godownData.length > 0 ? (
                    godownData
                      .filter((data) => data.godown_id !== godownID)
                      .map((data) => {
                        return (
                          <MenuItem key={data.godown_id} value={data.godown_id}>
                            {data.godown_name}
                          </MenuItem>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </TextField>
              ) : (
                <></>
              )}

              <TextField
                value={editStatus}
                onChange={(e) => {
                  setEditStatus(e.target.value);
                }}
                select
                label="Select Status"
                fullWidth
                margin="normal"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>

              <div className={style.createPopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => godownUpdate()}
                  variant="contained"
                >
                  SUBMIT
                </button>
                <button
                  className={style.closeButton}
                  variant="outlined"
                  onClick={() => {
                    setEditPopup(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {deletePopup ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.deletePopup}>
            <div className={style.deletePopupHeader}>
              <div className={style.deletePopupHeadClose}>
                <IoMdClose
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                />
              </div>
            </div>
            <div className={style.deletePopupSection}>
              <div className={style.deletePopupText}>
                <p>Are you sure want to delete?</p>
              </div>
              <div className={style.deletePopupButtons}>
                <button
                  className={style.submitButton}
                  onClick={() => godownDelete()}
                >
                  Delete
                </button>
                <button
                  className={style.closeButton}
                  onClick={() => {
                    setDeletePopup(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default GoDown;
