import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import style from "./InventoryManagement.module.css";
import { baseUrl } from "../Url";
import { useOutletContext } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { IoFilter, IoSearch } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

function InventoryManagement() {
  const [inventoryAssetData, setInventoryAssetData] = useState([]);
  const [sortMenuItem, setSortMenuItem] = useState([]);
  const [selectedSort, setSelectedSort] = useState("");
  const [sortListShow, setSortListShow] = useState(false);
  const [searchValue, setSearchVAlue] = useState("");
  const [loading, setLoading] = useState(false);

  const { selectedNewHub } = useOutletContext();
  const token = JSON.parse(localStorage.getItem("access-token"));
  const sortRef = useRef(null);
  const navigate = useNavigate();

  const handleSortSelection = (location) => {
    setSelectedSort(location);
    inventoryAssetList(selectedNewHub, searchValue, location);
  };

  async function sortItems() {
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order: "location",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          const updatedData = [{ location: "All" }, ...data.data];
          setSortMenuItem(updatedData);
        }
      });
  }

  async function inventoryAssetList(hubId, search, sort) {
    const body = {
      hub_id: hubId ? hubId : "",
      search: search ? search : "",
      sort: sort ? (sort === "All" ? "" : sort) : "",
    };
    setLoading(true);
    await fetch(`${baseUrl}/inventory/asset/listing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "menuItemsListing");
        if (data.status === true) {
          setInventoryAssetData(data.data);
        }
      })
      .finally(() => setLoading(false)); 
  }

  useEffect(() => {
    if (selectedNewHub) {
      inventoryAssetList(selectedNewHub);
      sortItems();
    }
  }, [selectedNewHub]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortListShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Inventory Management</p>
        </div>
        <div className={style.headerActions}>
          <div className={style.filterSection}>
            <IoFilter
              onClick={(e) => {
                setSortListShow(!sortListShow);
                e.stopPropagation();
              }}
            />
            {sortListShow && (
              <div ref={sortRef} className={style.sortValueContainer}>
                {sortMenuItem.map((list, i) => {
                  return (
                    <div
                      className={style.sortValue}
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSortSelection(list.location);
                      }}
                    >
                      {selectedSort === list.location ? (
                        <div className={style.activeSortValue}>
                          <FaCheck />
                          <p>{list.location}</p>
                        </div>
                      ) : (
                        <div className={style.nonActiveSortValue}>
                          <p>{list.location}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={style.search}>
            <IoSearch className={style.searchIcon} />
            <input
              type="text"
              placeholder="Search"
              className={style.searchInput}
              value={searchValue}
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchVAlue(newValue);
                if (newValue !== "") {
                  inventoryAssetList(selectedNewHub, newValue);
                } else {
                  inventoryAssetList(selectedNewHub);
                }
              }}
            />
          </div>
        </div>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.tableSection}>
        <TableContainer component={Paper} className={style.table}>
          {/* <div className={style.tableHeading}>Stock Items</div> */}
          {inventoryAssetData && inventoryAssetData.length > 0 ? (
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "20%",
                    }}
                  >
                    Image
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "20%",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "20%",
                    }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "20%",
                    }}
                  >
                    Location
                  </TableCell>
                  {/* <TableCell sx={{ width: "10%" }} align="center">Status</TableCell> */}
                  <TableCell
                    align="left"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "20%",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryAssetData.map((asset) => {
                  return (
                    <TableRow key={asset.asset_id}>
                      <TableCell>
                        {asset.asset_image ? (
                          <img
                            style={{ width: "80px", height: "80px" }}
                            src={asset?.asset_image}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {asset.asset_name}
                      </TableCell>
                      <TableCell align="left">{asset.asset_code}</TableCell>
                      <TableCell align="left">{asset.location}</TableCell>
                      {/* <TableCell align="center">{list.Status==="Active"?<FaRegCircleCheck style={{color:'green',fontSize:"1.35rem"}}/>:list.Status==="Inactive"?<IoIosCloseCircleOutline style={{color:'red',fontSize:"1.35rem"}}/>:""}</TableCell> */}
                      <TableCell align="left">
                        <button
                          className={style.detailButton}
                          onClick={() => {
                            navigate("/inventory/details", {
                              state: {
                                asset_id: asset.asset_id,
                                asset_location: asset.location,
                              },
                            });
                          }}
                        >
                          Details
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Alert severity="error" color="warning">
              No assets found..!
            </Alert>
          )}
        </TableContainer>
      </div>
       )}
    </div>
  );
}

export default InventoryManagement;
