import React, { useEffect, useState } from 'react';
import style from './CustomerCreation.module.css'
import { Alert, Box, FormControl, MenuItem, OutlinedInput, Select, Snackbar, TextField, Checkbox, Input } from '@mui/material';
import { Country, State, City } from 'country-state-city';
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';




import { baseUrl } from '../Url';

function CustomerCreation() {


    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("access-token"));


    const [customerGroupData, setCustomerGroupData] = useState([]);
    const [createName, setCreateName] = useState("");
    const [createEmail, setCreateEmail] = useState("");
    const [createPhone, setCreatePhone] = useState("");
    const [customerType, setCustomerType] = useState("B2B");
    const [businessName, setBusinessName] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [location, setLocation] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [taxAccountNo, settaxAccountNo] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState(null);
    const [taxClassification, setTaxClassification] = useState("");
    const [priceLevel, setPriceLevel] = useState("");
    const [createCustomerGroup, setCreateCustomerGroup] = useState([]);
    const [validationFail, setValidationFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [roleFail, setRoleFail] = useState(false);
    const [apiDescription, setApiDescription] = useState("");
    const [customerSuccessSnack, setCustomerSuccessSnack] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [status, setStatus] = useState("")


    async function segmentList() {
        const reqData = {
            source: "web",
        };
        await fetch(`${baseUrl}/tax/segment/list`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reqData),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.status === true) {
                    setSegmentData(data.data);
                }
            });
    }

    const createValidation = () => {
        if (!createName) {
            setErrorMessage("Name is required");
            return false;
        }
        if (!createEmail) {
            setErrorMessage("Email is required");
            return false;
        }
        if (!createPhone) {
            setErrorMessage("Phone is required");
            return false;
        }
        if (customerType==="B2B" && !businessName) {
            setErrorMessage("Business name is required");
            return false;
        }
        if (customerType==="B2B" && !latitude) {
            setErrorMessage("Latitude is required");
            return false;
        }
        if (customerType==="B2B" && !longitude) {
            setErrorMessage("Longitude is required");
            return false;
        }
        if (customerType==="B2B" && !location) {
            setErrorMessage("Location is required");
            return false;
        }
        if (customerType==="B2C" && !zipCode) {
            setErrorMessage("Zipcode is required");
            return false;
        }
        if (customerType==="B2C" && !gender) {
            setErrorMessage("Gender is required");
            return false;
        }
        if (!address1) {
            setErrorMessage("Address line 1 is required");
            return false;
        }
        if (!address2) {
            setErrorMessage("Address line 2 is required");
            return false;
        }
        if (!country) {
            setErrorMessage("Country is required");
            return false;
        }
        if (!state) {
            setErrorMessage("State is required");
            return false;
        }
        if (!city) {
            setErrorMessage("City is required");
            return false;
        }
        if (!taxClassification) {
            setErrorMessage("Tax classification is required");
            return false;
        }
        if (!status) {
            setErrorMessage("Status is required");
            return false;
          }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(createEmail)) {
            setErrorMessage("Invalid email format");
            return;
        }
        const phoneRegex = /^\+?[0-9\s-()]+$/;
        if (!phoneRegex.test(createPhone)) {
            setErrorMessage("Invalid phone number");
            return;
        }
        return true;
    }

    async function customerGroupList() {
        await fetch(`${baseUrl}/customer/group/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, 'customer group list');
                if (data.status === true) {
                    setCustomerGroupData(data.data)
                }
            })
    }

    //api call customer creation
    async function customerCreation() {
        const reqData = {
            customer_name: createName,
            customer_email: createEmail,
            customer_phone: createPhone,
            customer_type: customerType,
            customer_group: selectedGroup,
            bussiness_name: businessName,
            latitude: latitude,
            longitude: longitude,
            location: location,
            address: [{ address1: address1, address2: address2 }],
            city: city,
            state: state,
            country: country,
            zip_code: zipCode,
            gender: gender,
            dob: dob,
            tax_account_no: taxAccountNo,
            price_level: priceLevel,
            tax_classification: taxClassification,
            status:status

        }
        if (!createValidation()) {
            setValidationFail(true);
            return
        }
        else {
            await fetch(`${baseUrl}/customer/creation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(reqData)
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data, '');
                    if (data.status === true) {
                        setCustomerSuccessSnack(true)
                        setTimeout(() => {
                            navigate("/customers/customer");
                        }, 2000);
                    } else {
                        setApiDescription(data.description);
                        setRoleFail(true);
                    }
                })
        }
    }

    useEffect(() => {
        customerGroupList()
        segmentList()
    }, [])

    useEffect(() => {
        setState("");
        setCity("");
    }, [country]);

    useEffect(() => {
        setCity("");
    }, [state]);


    return (
        <div className={style.container}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={customerSuccessSnack}
                sx={{ width: "20rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setCustomerSuccessSnack(false);
                }}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {customerSuccessSnack ? "Customer created successfully" : ""}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={roleFail}
                sx={{ width: "20rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setRoleFail(false);
                }}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {apiDescription}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={validationFail}
                sx={{ width: "15rem" }}
                autoHideDuration={3000}
                onClose={() => {
                    setValidationFail(false);
                }}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%", fontSize: "1.15rem" }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className={style.header}>
                <div className={style.heading}>
                    <p>Customer Creation</p>
                </div>
                {/* <div className={style.csvButton}>
                    <button>
                        IMPORT
                    </button>
                </div> */}
                <button
                    onClick={() => {
                        customerCreation()
                    }}
                    className={style.buttonSection}
                >
                    Create Customer
                </button>
            </div>

            <div className={style.customerType}>
                <div className={style.typeLabel}>
                    <p>Customer Type</p>
                    <TextField
                        className={style.label}
                        select
                        value={customerType}
                        fullWidth
                        size='small'
                        margin="normal"
                        onChange={(e) => setCustomerType(e.target.value)}
                    >
                        <MenuItem value="B2B">B2B</MenuItem>
                        <MenuItem value="B2C">B2C</MenuItem>
                    </TextField>
                </div>
            </div>

            <div className={style.tableSection}>
                {customerType === "B2B" && (
                    <>
                        <div className={style.leftSection}>
                            <div className={style.label}>
                                <p>Contact Person Name</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={createName}
                                    onChange={(e) => {
                                        setCreateName(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Email</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={createEmail}
                                    onChange={(e) => {
                                        setCreateEmail(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Phone</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={createPhone}
                                    onChange={(e) => {
                                        setCreatePhone(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Business Name</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={businessName}
                                    onChange={(e) => {
                                        setBusinessName(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Latitude</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={latitude}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                            setLatitude(value);
                                        }
                                      }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Longitude</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={longitude}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                            setLongitude(value);
                                        }
                                      }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Location</p>
                                <TextField
                                    className={style.textfield}
                                    value={location}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Address Line 1</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={address1}
                                    onChange={(e) => {
                                        setAddress1(e.target.value)
                                    }}
                                />
                            </div>

                        </div>
                        <div className={style.rightSection}>
                            <div className={style.label}>
                                <p>Address Line 2</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={address2}
                                    onChange={(e) => {
                                        setAddress2(e.target.value)
                                    }}
                                />
                            </div>
                            {/* 11 */}
                            <div className={style.label}>
                                <p>Country</p>
                                <FormControl
                                    fullWidth
                                    sx={{ mt: 1, minWidth: 120 }}
                                    className={style.label}
                                >
                                    <Select
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {Country.getAllCountries().map((country) => (
                                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={style.label}>
                                <p>State</p>
                                <FormControl
                                    fullWidth

                                    className={style.label}
                                >
                                    <Select
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        disabled={!country}
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {country &&
                                            State.getStatesOfCountry(country).map((state) => (
                                                <MenuItem key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={style.label}>
                                <p>City</p>
                                <TextField
                                    className={style.textfield}
                                    value={city}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>

                            <div className={style.label}>
                                <p>Tax Account No:</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={taxAccountNo}
                                    onChange={(e) => {
                                        settaxAccountNo(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Tax Classification</p>
                                <TextField
                                    select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={taxClassification}
                                    onChange={(e) => {
                                        setTaxClassification(e.target.value)
                                    }}
                                >
                                    {segmentData.map((segment, i) => {
                                        return (
                                            <MenuItem key={i} value={segment.tax_segment_code}>
                                                {segment.tax_segment_name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>

                            </div>
                            <div className={style.label}>
                                <p>Price Level</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={priceLevel}
                                    onChange={(e) => {
                                        setPriceLevel(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Customer Group</p>
                                <FormControl fullWidth margin="normal">
                                    <Select
                                        multiple
                                        value={selectedGroup}
                                        onChange={(e) => setSelectedGroup(e.target.value)}
                                        input={<OutlinedInput />}
                                    >

                                        {customerGroupData.map((group) => (
                                            <MenuItem key={group.customergroup_id} value={group.customergroup_id}>
                                                <Checkbox checked={selectedGroup.indexOf(group.customergroup_id) > -1} />
                                                {/* <ListItemText primary={group.customer_group_name} /> */}
                                                {group.customer_group_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={style.label}>
                                <p>Status</p>
                                <TextField
                                     select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                    }}
                                    >
                                    <MenuItem value="active">Active</MenuItem>
                                    <MenuItem value="inactive">Inactive</MenuItem>
                                    </TextField>
                            </div>
                        </div>
                    </>
                )}

                {customerType === "B2C" && (
                    <>
                        <div className={style.leftSection}>
                            <div className={style.label}>
                                <p>Full Name</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={createName}
                                    onChange={(e) => {
                                        setCreateName(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Contact Number</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={createPhone}
                                    onChange={(e) => {
                                        setCreatePhone(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Email</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={createEmail}
                                    onChange={(e) => {
                                        setCreateEmail(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Address Line 1</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={address1}
                                    onChange={(e) => {
                                        setAddress1(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Address Line 2</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={address2}
                                    onChange={(e) => {
                                        setAddress2(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Customer Group</p>
                                <FormControl fullWidth margin="normal">
                                    <Select
                                        multiple
                                        value={selectedGroup}
                                        onChange={(e) => setSelectedGroup(e.target.value)}
                                        input={<OutlinedInput />}
                                    >

                                        {customerGroupData.map((group) => (
                                            <MenuItem key={group.customergroup_id} value={group.customergroup_id}>
                                                <Checkbox checked={selectedGroup.indexOf(group.customergroup_id) > -1} />
                                                {group.customer_group_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* 11 */}
                        <div className={style.rightSection}>
                            <div className={style.label}>
                                <p>Country</p>
                                <FormControl
                                    fullWidth
                                    sx={{ mt: 1, minWidth: 120 }}
                                    className={style.label}
                                >
                                    <Select
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {Country.getAllCountries().map((country) => (
                                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className={style.label}>
                                <p>State</p>
                                <FormControl
                                    fullWidth
                                    className={style.label}
                                >
                                    <Select
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        disabled={!country}
                                        sx={{ width: '100%' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {country &&
                                            State.getStatesOfCountry(country).map((state) => (
                                                <MenuItem key={state.isoCode} value={state.isoCode}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={style.label}>
                                <p>City</p>
                                <TextField
                                    className={style.textfield}
                                    value={city}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Zipcode</p>
                                <TextField
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                />
                            </div>
                            <div className={style.label}>
                                <p>Gender</p>
                                <TextField
                                    select
                                    // className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={gender}
                                    onChange={(e) => {
                                        setGender(e.target.value);
                                    }}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </TextField>
                            </div>
                            <div className={style.label}>
                                <p>DOB</p>
                                <LocalizationProvider dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker sx={{ width: '100%', margin: '10px 0' }}
                                        value={dob}
                                        onChange={(newValue) => setDob(newValue)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className={style.label}>
                                <p>Tax Classification</p>
                                <TextField
                                    select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={taxClassification}
                                    onChange={(e) => {
                                        setTaxClassification(e.target.value)
                                    }}
                                >
                                    {segmentData.map((segment, i) => {
                                        return (
                                            <MenuItem key={i} value={segment.tax_segment_code}>
                                                {segment.tax_segment_name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>

                            </div>
                            <div className={style.label}>
                                <p>Status</p>
                                <TextField
                                     select
                                    className={style.textfield}
                                    fullWidth
                                    margin="normal"
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value)
                                    }}
                                    >
                                    <MenuItem value="active">Active</MenuItem>
                                    <MenuItem value="inactive">Inactive</MenuItem>
                                    </TextField>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CustomerCreation