import React, { useState, useEffect, useRef } from "react";
import style from "./Return.module.css";
import { IoSearch, IoFilter } from "react-icons/io5";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Alert, Box, Autocomplete, TextField } from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { useOutletContext } from 'react-router-dom';
import Loader from "../Loader/Loader";

const Return = () => {
  const { selectedNewHub } = useOutletContext();

  const navigate = useNavigate();
  const sortRef = useRef(null);

  const token = JSON.parse(localStorage.getItem("access-token"));

  const [returnData, setReturnData] = useState([]);
  const [filterMenuItem, setFilterMenuItem] = useState([]);
  const [sortValueId, setSortValueId] = useState(0);
  const [sortListShow, setSortListShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(false);

  const convertDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const handleSortValue = (value) => {
    setSortValueId(value);
    returnList(selectedNewHub, searchValue, fromDate, toDate, value);
  };

  async function returnList(hubId, search, from, to, sort) {
    if (from && !to) {
      to = new Date().toISOString().split('T')[0];
      setToDate(to);
    }
    const body = {
      hub_id: hubId ? hubId : "",
      search: search ? search : "",
      from_date: from ? from : "",
      to_date: to ? to : "",
      sort: sort ? sort : 0,
    };
    setLoading(true);
    await fetch(`${baseUrl}/list/return/order/admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'return orders');
        if (data.status === true) {
          setReturnData(data.data);
        }
      })
      .finally(() => setLoading(false)); 
  }

  useEffect(() => {
    if (selectedNewHub) {
      returnList(selectedNewHub);
    }
  }, [selectedNewHub]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortListShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Return Orders</p>
        </div>
        <div className={style.headerActions}>
          <div className={style.dateSection}>
            <div className={style.dateSectionLeft}>
              <label>From </label>
              <input
                type="date"
                className={style.dateInput}
                value={fromDate}
                onChange={(e) => {
                  const newFromDate = e.target.value;
                  setFromDate(newFromDate);
                  returnList(selectedNewHub, searchValue, newFromDate, toDate);
                }}
              />
            </div>
            <div className={style.dateSectionRight}>
              <label>To</label>
              <input
                type="date"
                className={style.dateInput}
                value={toDate}
                onChange={(e) => {
                  const newToDate = e.target.value;
                  setToDate(newToDate);
                  returnList(selectedNewHub, searchValue, fromDate, newToDate);
                }}
              />
            </div>
          </div>
          <div className={style.filterSection}>
            <IoFilter onClick={(e) => {
              setSortListShow(!sortListShow);
              e.stopPropagation();
            }} />
            {sortListShow && (
              <div ref={sortRef} className={style.sortValueContainer}>
                {filterMenuItem.map((list, i) => (
                  <div
                    className={style.sortValue}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSortValue(list.status_code);
                    }}
                  >
                    {sortValueId === list.status_code ? (
                      <div className={style.activeSortValue}>
                        <FaCheck />
                        <p>{list.status_name}</p>
                      </div>
                    ) : (
                      <div className={style.nonActiveSortValue}>
                        <p>{list.status_name}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={style.search}>
            <IoSearch className={style.searchIcon} />
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchValue(newValue);
                returnList(selectedNewHub, newValue, fromDate, toDate);
              }}
              className={style.searchInput} />
          </div>
        </div>
      </div>
      {loading ? (
              <Loader />
            ) : (
      <div className={style.tableSection}>
        <TableContainer className={style.table} component={Paper}>
          {returnData && returnData.length > 0 ? (
            <Table aria-label="return orders table">
              <TableHead className={style.tableHead}>
                <TableRow>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Order Number</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Customer</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Return Date</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Address</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Return Status</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Staff</TableCell>
                  <TableCell align="left" style={{
                      fontFamily: "Poppins",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                      color: "#000",
                      width: "16%",
                    }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {returnData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{data.order_number}</TableCell>
                    <TableCell align="left">{data.customer_name}</TableCell>
                    <TableCell align="left">{convertDateFormat(data.created_timestamp.$date)}</TableCell>
                    <TableCell align="left">{data.customer_address[0]?.address1}</TableCell>
                    <TableCell align="left">{data.return_order_status}</TableCell>
                    <TableCell align="left">{data.staff_details?.firstname}</TableCell>
                    <TableCell align="left">
                      <button
                        className={style.detailButton}
                        onClick={() => {
                          navigate("/return/details", {
                            state: { return_id: data.return_order_id }
                          });
                        }}
                      >
                        Details
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Alert severity="error" color="warning">
              No return orders found..!
            </Alert>
          )}
        </TableContainer>
      </div>
       )}
    </div>
  );
};

export default Return;
