import React, { useState, useEffect } from "react";
import style from './ReturnDetsils.module.css'
import sampleImage from "../../assets/About.jpg";
import { FaAngleDown } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import {
  MenuItem,
  TextField,
  FormControl,
  Select,
  Checkbox,
  InputLabel,
  Alert,
  Snackbar,
  Box,
  Autocomplete,
  Modal,
  Paper
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../Url";
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const ReturnDetails = () => {
  const { selectedNewHub } = useOutletContext();

  const navigate = useNavigate()

  const location = useLocation()
  const return_id = location.state?.return_id
  const token = JSON.parse(localStorage.getItem("access-token"));

  const [returnData, setReturnData] = useState({})
  const [staffData, setStaffData] = useState([])
  const [filterMenuItem, setFilterMenuItem] = useState([])
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [slotData, setSlotData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState("")
  const [selectedStaff, setSelectedStaff] = useState("")
  const [orderID, setOrderID] = useState("")
  const [selectedAssets, setSelectedAssets] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [assetData, setAssetData] = useState([])
  const [vehicleData, setVehicleData] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState("")

  const convertDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };



  function getSlotTimes(time) {
    if (!time) return "N/A";
    const [hour, minute] = time.split(":").map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const adjustedHour = hour % 12 || 12;
    return `${String(adjustedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${ampm}`;
  }


  const handleStaffChange = (e) => {
    console.log('Staff selected:', e.target.value);
    setSelectedStaff(e.target.value);
  };

  async function getReturnOrderDetails() {

    await fetch(`${baseUrl}/return/order/details/admin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        return_order_id: return_id
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setSelectedStatus(data.data?.status_code || "")
          // setOrderID(data.data?.order_data?.[0]?.order_id || "")
          setReturnData(data.data || {});
          setSelectedVehicle(data.data?.vehicle_id || "")
          // setSelectedAssets(data.data?.asset_data?data.data.asset_data:[]);
        }
      })
  }

  async function returnUpdate() {
    await fetch(`${baseUrl}/return/order/update/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        return_order_id: return_id,
        order_status: selectedStatus,
        order_staff: selectedStaff,
        vehicle_id: selectedVehicle
      }),
    })
      .then((res) => res.json())
      .then((data) => {

        if (data.status === true) {
          setUpdateSuccess(true)
          getReturnOrderDetails();
          setTimeout(() => {
            navigate("/return")
          }, 2000);

        }

      });
  }

  async function staffList() {

    await fetch(`${baseUrl}/delivery/staff/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        org_id: selectedNewHub,
        role: "Delivery Staff"
      })
    })
      .then((res) => res.json())
      .then((data) => {

        if (data.status === true && data.data.length > 0) {
          setStaffData(data.data)
        }
        else {
          setStaffData([])
        }

      })
  }

  async function filterItems() {
    var storedToken = JSON.parse(localStorage.getItem("access-token"));
    await fetch(`${baseUrl}/order/progress/status/v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storedToken}`,
      },
      body: JSON.stringify({
        order: "return order"
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true && data.data.length > 0) {
          setFilterMenuItem(data.data);
        }
        else {
          setFilterMenuItem([])
        }

      });
  }

  async function vehicleList() {
    const reqData = {
      hub_id: selectedNewHub,
    };
    await fetch(`${baseUrl}/list/vehicle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(reqData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setVehicleData(data.data);
        }
      });
  }

  useEffect(() => {
    if (return_id) {
      getReturnOrderDetails();
    }
  }, [return_id])

  useEffect(() => {
    if (selectedNewHub) {
      filterItems()
      staffList()
      // slotList()
      // listItemAssets()
      vehicleList()
    }
  }, [selectedNewHub])

  useEffect(() => {
    console.log(assetData, "asset");

  }, [assetData])


  useEffect(() => {

    if (staffData.length > 0) {
      setSelectedStaff(returnData?.staff_id || "")

    }
  }, [staffData])

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={updateSuccess}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setUpdateSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          {
            updateSuccess ? "Order updated successfully" : ""}
        </Alert>
      </Snackbar>
      <div className={style.header}>
        <div className={style.heading}>
          <p>Return Details</p>
        </div>
      </div>
      {Object.keys(returnData).length === 0 ? (
        <p>No data found</p>
      ) : (
        <div className={style.body}>
          <div className={style.bodyLeft}>
            <div className={style.orderDetails}>
              <div className={style.orderDetailHead}>
                <div className={style.orderNumber}>
                  <p>Ret order no : {returnData?.return_order_number || "N/A"}</p>
                  <p>Referral no : {returnData?.order_number || "N/A"}</p>
                </div>
                <div className={style.slotData}><span>Date:{convertDateFormat(returnData?.created_date || "N/A")}</span>
                  <span>  Slot : {returnData ? getSlotTimes(returnData.slot_start) : "N/A"} - {returnData ? getSlotTimes(returnData.slot_end) : "N/A"}</span>
                </div>
              </div>
              <div className={style.orderDetailBody}>
                {returnData?.asset_details?.map((item, index) => (
                  <div className={style.orderDetailCard} key={index}>
                    <div className={style.orderDetailCardLeft}>
                      <div className={style.cardImage}>
                        <img
                          style={{ width: "80px", height: "80px" }}
                          src={item.asset_image ? item.asset_image : sampleImage}
                          alt="sample"
                        />
                      </div>
                    </div>
                    <div className={style.orderDetailCardRight}>
                      <div className={style.cardRightTop}>
                        <span>{item.asset_name}</span>
                        {/* <p>₹{item.total_amount}</p> */}
                        {/* <span>{item.asset_code}</span> */}

                      </div>
                      {/* <div className={style.cardRightBottom}>
                        <p>Quantity:{item.quantity}</p>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className={style.orderPayment}>
                <div className={style.orderPaymentLeft}>
                  <p>Order note:</p>
                  <span>{returnData?.customer_remark || "N/A"}</span>
                </div>
                <div className={style.orderPaymentRight}>
                  <div className={style.orderPaymentRightSection}>
                    <div className={style.paymentData}>
                      <p>Sub Total</p>
                      <p>₹{returnData?.gross_amount || "N/A"}</p>
                    </div>
                    <div className={style.paymentData}>
                      <p>Discount</p>
                      <p>₹{returnData?.total_discount_amount || "0"}</p>
                    </div>
                    <div className={style.paymentData}>
                      <p>Tax</p>
                      <p>₹{returnData?.total_tax_amount || "N/A"}</p>
                    </div>
                    <div className={style.paymentSeparateLine}></div>
                    <div className={style.paymentTotalData}>
                      <p>Total</p>
                      <p>₹{returnData?.net_amount || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className={style.customerDetails}>
              <div className={style.customerDetailsHead}>
                <p>Customer Details</p>
                <FaAngleDown />
              </div>
              <div className={style.customerDetailsBody}>
                <div className={style.customerDetailsBodyLeft}>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Name</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <span>{returnData?.customer_name || 'N/A'}</span>
                    </div>
                  </div>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Email</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <span>{returnData?.customer_email || 'N/A'}</span>
                    </div>
                  </div>
                </div>
                <div className={style.customerDetailsBodyRight}>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Phone</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <span>{returnData?.customer_phone || 'N/A'}</span>
                    </div>
                  </div>
                  <div className={style.customerDetailRow}>
                    <div className={style.customerDetailRowData}>
                      <p>Address</p>
                    </div>
                    <div className={style.customerDetailRowData}>
                      <div>
                      <p>{returnData?.address_details?.name || 'N/A'}</p>
                      <p>{returnData?.address_details?.city || 'N/A'}</p>
                      <p>{returnData?.address_details?.district || 'N/A'}</p>
                      <p>{returnData?.address_details?.pin_code || 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.bodyRight}>
            <div className={style.staffDetails}>
              <div className={style.staffDetailsHead}>
                <p>Staff Details</p>
                <MdEdit />
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Name</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <FormControl fullWidth>
                    <InputLabel>Select staff</InputLabel>
                    <Select
                      //  disabled={returnData.delivery_data?.order_status===3||returnData.delivery_data?.order_status===4}
                      size="small"
                      value={selectedStaff || ""}
                      onChange={handleStaffChange}
                      label="Select staff"
                    >
                      {staffData.length > 0 ? staffData.map((staff, i) => (
                        <MenuItem key={i} value={staff.user_id}>{staff.firstname}</MenuItem>
                      )) : (
                        <MenuItem value="" disabled>
                          No staff available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Phone</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>{returnData?.staff_phone || 'N/A'}</span>
                </div>
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Return status</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <FormControl fullWidth size="small" disabled={returnData.delivery_data?.order_status === 3 || returnData.delivery_data?.order_status === 4}>
                    <InputLabel>Select Status</InputLabel>
                    <Select
                      value={selectedStatus ? selectedStatus : ""}
                      onChange={(e) => {
                        if (e.target && e.target.value) {
                          setSelectedStatus(e.target.value);
                        }
                      }}
                      label="Select Status"
                    >
                      {filterMenuItem && filterMenuItem.length > 0 ? filterMenuItem.map((status, i) => {
                        return (
                          <MenuItem key={i} value={status.status_code}>
                            {status.status_name}
                          </MenuItem>
                        );
                      }) : ""}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className={style.staffDetails}>
              <div className={style.staffDetailsHead}>
                <p>Vehicle Details</p>
                <MdEdit />
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Vehicle</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <FormControl fullWidth>
                    <InputLabel>Select vehicle</InputLabel>
                    <Select
                      //  disabled={returnData.delivery_data?.order_status===3||returnData.delivery_data?.order_status===4}
                      size="small"
                      value={selectedVehicle || ""}
                      onChange={(e) => {
                        if (e.target && e.target.value) {
                          setSelectedVehicle(e.target.value);
                        }
                      }}
                      label="Select vehicle"
                    >
                      {vehicleData.length > 0 ? vehicleData.map((vehicle, i) => (
                        <MenuItem key={i} value={vehicle.vehicle_id}>{vehicle.vehicle_name}</MenuItem>
                      )) : (
                        <MenuItem value="" disabled>
                          No vehicle available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

            </div>
            <div className={style.paymentDetails}>
              <div className={style.staffDetailsHead}>
                <p>Payment Details</p>
                <MdEdit />
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Payment mode</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>Cash on delivery</span>
                </div>
              </div>
              <div className={style.customerDetailRow}>
                <div className={style.customerDetailRowData}>
                  <p>Payment status</p>
                </div>
                <div className={style.customerDetailRowData}>
                  <span>Unpaid</span>
                </div>
              </div>
            </div>
            <div className={style.buttonSection}>
              <button disabled={returnData.delivery_data?.order_status === 3 || returnData.delivery_data?.order_status === 4} onClick={() => {
                returnUpdate()
              }}>Update</button>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={modalOpen}
        aria-labelledby="create-order-modal"
        aria-describedby="create-order-form"
      >
        <Paper className={style.modal} elevation={6}>
          <h2 id="create-order-modal">Map Assets</h2>
          <div className={style.modalContent}>
            <Box sx={{ minWidth: 200 }}>
              <Autocomplete
                multiple
                id="asset-select"
                options={assetData}
                getOptionLabel={(option) => option.ItemName}
                value={selectedAssets}
                onChange={(event, newValue) => {
                  setSelectedAssets(newValue);  // Update with entire object
                }}
                isOptionEqualToValue={(option, value) => option.itemID === value.itemID}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an Asset"
                    variant="outlined"
                    size="small"
                    sx={{
                      "& fieldset": {
                        borderColor: "#59293a",
                        borderWidth: 2,
                      },
                      "&:hover fieldset": {
                        borderColor: "#794a57",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#a96b84",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <div className={style.modalActions}>
              <button
                variant="contained"
                onClick={() => {
                  setModalOpen(false);
                }}
                className={style.EditSub}
              >
                Confirm
              </button>
              <button
                variant="outlined"
                onClick={() => {
                  setModalOpen(false);
                }}
                className={style.EditClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Paper>
      </Modal>

    </div>
  )
}

export default ReturnDetails