import React, { useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./Sidebar.module.css";
import logo2 from "../../assets/muglogo.png";
import logo1 from "../../assets/logo.png";
import AdminLogo from "../../assets/user admin.png";
import { MdOutlineHub } from "react-icons/md";
import { PiKeyReturn, PiListBulletsBold, PiUsersBold } from "react-icons/pi";
import { RxExit } from "react-icons/rx";
import { LiaSyncSolid } from "react-icons/lia";
import { FaRegAddressCard } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import { TbUsersGroup } from "react-icons/tb";
import { MdPersonOutline } from "react-icons/md";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { TbUserHexagon } from "react-icons/tb";
import { GrUserAdmin } from "react-icons/gr";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { AiOutlineClose } from "react-icons/ai";
import { TbCategoryPlus } from "react-icons/tb";
import { BiFoodMenu } from "react-icons/bi";
import { RiFileList3Line,RiEBikeLine } from "react-icons/ri";
import { TiShoppingCart } from "react-icons/ti";
import { RiOrganizationChart } from "react-icons/ri";
import { MdManageHistory,MdOutlineFoodBank } from "react-icons/md";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbBuildingWarehouse } from "react-icons/tb";

import { baseUrl } from "../Url";
import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput
} from "@mui/material";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";

function Sidebar({hubList,selectedHub,setSelectedHub}) {

  const location = useLocation();
  const navigate = useNavigate();


  const sideBarMenu = [
    {
      icon: <MdOutlineHub />,
      navigator: "/hub",
      title: "Hub Management",
      moduleCode: "MOD0001",
      subModule: [
        {
          icon: <RiOrganizationChart />,
          // icon: roleCode === "ROLE002" ? <RiOrganizationChart /> : null,
          navigator: "/hub",
          title: "Hub",
          moduleCode: "MOD0001",
          specialPaths: ["/",],
        },
        {
          icon: <IoLocationSharp />,
          navigator: "/hub/Locations",
          title: "Location",
          moduleCode: "MOD0003",
        },
        {
          icon: <HiOutlineReceiptTax />,
          navigator: "/tax/segment",
          title: "Tax segment",
          moduleCode: "MOD00019",
        },
        {
          icon: <HiOutlineReceiptTax />,
          navigator: "/tax/group",
          title: "Tax group",
          moduleCode: "MOD00018",
        },
        {
          icon: <HiOutlineReceiptTax />,
          navigator: "/tax/item",
          title: "Tax Item",
          moduleCode: "MOD00017",
        },
        {
          icon: <RiOrganizationChart />,
          navigator: "/slots",
          title: "Slots",
          moduleCode: "MOD0016",
        },
        {
          icon: <MdOutlineFoodBank />,
          navigator: "/godown",
          title: "GoDown",
          moduleCode: "MOD0014",
        },
        {
          icon: <RiEBikeLine />,
          navigator: "/vehicle",
          title: "Vehicle Management",
          moduleCode: "MOD0015",
        },
      ],
    },
    {
      icon: <TbUsersGroup />,
      navigator: "/customers/customer",
      title: "Customer Management",
      moduleCode: "MOD0004",
      subModule: [
        {
          icon: <MdPersonOutline />,
          navigator: "/customers/customer",
          title: "Customer",
          moduleCode: "MOD0004",
          specialPaths: ["/customers/customer/creation",],
        },
        {
          icon: <HiOutlineRectangleGroup />,
          navigator: "/customers/customergroup",
          title: "Customer Group",
          moduleCode: "MOD0005",
        },
      ],
    },
    {
      icon: <PiUsersBold />,
      navigator: "/users/user",
      title: "User Management",
      moduleCode: "MOD0002",
      subModule: [
        {
          icon: <TbUserHexagon />,
          navigator: "/users/user",
          title: "User",
          moduleCode: "MOD0002",
        },
        {
          icon: <GrUserAdmin />,
          navigator: "/users/roles",
          title: "Roles",
          moduleCode: "MOD0006",
        },
      ],
    },
    {
      icon: <RiFileList3Line />,
      navigator: "/category",
      title: "Catalogue",
      moduleCode: "MOD0007",
      subModule: [
        {
          icon: <TbCategoryPlus />,
          navigator: "/category",
          title: "Category",
          moduleCode: "MOD0007",
        },
        {
          icon: <BiFoodMenu />,
          navigator: "/stockitems",
          title: "Stock Items",
          moduleCode: "MOD0008",
          specialPaths: ["/itemcreation", "/item/update"],
        },
        {
          icon: <PiListBulletsBold />,
          navigator: "/attribute",
          title: "Attribute",
          moduleCode: "MOD0009",
        },
      ],
    },
    {
      icon: <MdManageHistory />,
      navigator: "/orders",
      title: "Order Management",
      moduleCode: "MOD0010",
      subModule: [
        {
          icon: <TiShoppingCart />,
          navigator: "/orders",
          title: "Orders",
          moduleCode: "MOD0010",
          specialPaths: ["/orders/manageorder", "/order/manageorder/:orderId"],
        },
      ]
    },
    {
      icon: <AiOutlineDeliveredProcedure />,
      navigator: "/delivery",
      title: "Delivery Management",
      moduleCode: "MOD0011",
      subModule: [
        {
          icon: <AiOutlineDeliveredProcedure />,
          navigator: "/delivery",
          title: "Delivery",
          moduleCode: "MOD0011",
          specialPaths:["/delivery/details"]
        },
      ]
    },
    {
      icon: <PiKeyReturn />,
      navigator: "/return",
      title: "Return Management",
      moduleCode: "MOD0012",
      subModule: [
        {
          icon: <PiKeyReturn />,
          navigator: "/return",
          title: "Return",
          moduleCode: "MOD0012",
          specialPaths:["/return/details"]
        },
      ]
    },
    {
      icon: <TbBuildingWarehouse />,
      navigator: "/inventory",
      title: "Inventory Management",
      moduleCode: "MOD0013",
      subModule: [
        {
          icon: <TbBuildingWarehouse />,
          navigator: "/inventory",
          title: "Inventory",
          moduleCode: "MOD0013",
          // specialPaths:["/return/details"]
        },
      ]
    }
  ];

  const selectedMenuItemClass = style.selectedMenuItem;
  const selectedSubMenuClass = style.selectedSubMenu;


  const token = JSON.parse(localStorage.getItem("access-token"));
  const roleCode = localStorage.getItem("roleCode");
  const userName = localStorage.getItem("userName")

  const [selected, setSelected] = useState(null);
  const [logout, setLogout] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [roleBasedMenu,setRoleBasedMenu]=useState([]);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [sessionExpire,setSessionExpire]=useState(false);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  // const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
  };

  const pathsMatch = (path1, path2) => {
    const regex = new RegExp(`^${path2.replace(/:\w+/g, '\\w+')}$`);
    return regex.test(path1);
  };
  
  const findSelectedMenuItem = () => {
    const pathname = location.pathname;
    if (selectedMenuItem === null) {

    // Check for menu items that match the pathname or any special paths
    const foundMenuItem = sideBarMenu.find(menuItem => 
      pathsMatch(pathname, menuItem.navigator) || 
      menuItem.subModule?.some(subItem => 
        pathsMatch(pathname, subItem.navigator) || 
        subItem.specialPaths?.some(specialPath => pathsMatch(pathname, specialPath))
      )
    );
  
    if (foundMenuItem) {
      setSelectedMenuItem(foundMenuItem);
      const foundSubMenuItem = foundMenuItem.subModule?.find(subItem => 
        pathsMatch(pathname, subItem.navigator) || 
        subItem.specialPaths?.some(specialPath => pathsMatch(pathname, specialPath))
      );
  
      if (foundSubMenuItem) {
        setSelectedSubMenu(foundSubMenuItem);
      }
    }
   }
  };
  
  const handleMenuItemClick = (menuItem,index) => {
    setSelectedMenuItem(menuItem);
  };

  const handleSubmenuItemClick = (subItem) => {
    setSelectedSubMenu(subItem);
    if (subItem.navigator) {
      navigate(subItem.navigator);
    }
  }

  const handleLogout = (e) => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("adminStatus");
    localStorage.removeItem("adminUserId")
    localStorage.removeItem("role")
    localStorage.removeItem("roleId")
    localStorage.removeItem("roleCode")
    localStorage.removeItem("userName")
    localStorage.removeItem("organizationId")
    navigate("/login");
    // window.location.reload();
  };

  async function roleModuleView(roleCode) {
    await fetch(`${baseUrl}/fetch/module/module_sts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        role_code: roleCode
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          if (roleCode === "RL0001") {
            setRoleBasedMenu(sideBarMenu);
          } else {
            const moduleCodes = data.data.map((item) => item.module_code);
            const filteredMenu = sideBarMenu.filter((menuItem) =>
              moduleCodes.includes(menuItem.moduleCode)
            );
            setRoleBasedMenu(filteredMenu);
          }
        }
        else{
          if(data.description==="Signature has expired"){
            setSessionExpire(true)
          }
        }
      })
  }

  useEffect(() => {
    roleModuleView(roleCode)
  }, [roleCode]);

  useEffect(() => {
    findSelectedMenuItem();
  }, [location.pathname]);

  return (
    <div className={style.Sidebar}>
      <div className={style.logo}>
        <div className={style.img1}>
          <img src={logo1} alt="" />
        </div>
        <div className={style.img2}>
          <img src={logo2} alt="" />
        </div>
      </div>
      <div className={style.hubSelection}>
      <FormControl fullWidth size="small">
                  <Select
                  sx={{"&.Mui-focused": {
                    borderColor: "#a96b84",
                  },}}
                    value={selectedHub}
                    onChange={(e) => {
                      const selectedHubValue = e.target.value;
                      setSelectedHub(selectedHubValue);
                    }}
                    displayEmpty
                    input={
                      <OutlinedInput
                        sx={{
                          "& fieldset": {
                            borderColor: "#59293a",
                            borderWidth: 2,
                          },
                          "&fieldset:hover ": {
                            borderColor: "#794a57",
                          },
                          "& fieldset:active": {
                            borderColor: "#a96b84",
                          },
                          "&.Mui-focused": {
                            borderColor: "#a96b84",
                          },
                        }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      {" "}
                      <em>Select a Hub</em>
                    </MenuItem>
                    {hubList.map((item) => (
                      <MenuItem key={item.org_id} value={item.org_id}>
                        {item.org_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
      </div>
      <div className={style.SidebarHeader}>
        {roleBasedMenu.map((menuItem, index) => (
          <div onClick={() => handleMenuItemClick(menuItem)} key={index} className={`${style.MenuItem} ${selectedMenuItem === menuItem ? selectedMenuItemClass : ""}`}>
            <div >
              {menuItem.icon}
              <span>{menuItem.title}</span>
            </div>

            {selectedMenuItem?.moduleCode === menuItem.moduleCode && menuItem.subModule && (
              <div className={style.SubMenu} style={{ display: 'block' }}>
                {menuItem.subModule.map((subItem, subIndex) => (
                  <div key={subIndex} className={`${style.SubMenuItem} ${selectedSubMenu === subItem ? selectedSubMenuClass : ""}`} onClick={() => handleSubmenuItemClick(subItem)}>
                    {subItem.icon}
                    <span>{subItem.title}</span>
                  </div>
                ))}
              </div>
            )}

          </div>
        ))}
      </div>

      <div className={style.Sidebarfooter}>
        <hr />
        {roleCode === "RL0001" && (
        <div className={style.FooterHead}>
          <div className={style.footTitle}>
            <h3>ADMIN</h3>
          </div>
          <div
            className={`${style.AdminPerm} ${selected === "admindata" ? style.selected : ""
              }`}
            onClick={() => {
              setSelected("admindata");
              navigateToPage("/admin/AdminManage");
            }}
          >
            <p>
              <FaRegAddressCard /> Teaman Admin Management
            </p>
          </div>
        </div>
        )}

        <div
          className={`${style.AdminUser} ${selected === "account" ? style.selected : ""
            }`}
          onClick={() => setSelected("account")}
        >
          <img src={AdminLogo} alt="" />
          <div className={style.AdminDetail}>
            <h4>My Account</h4>
            <p>{userName}</p>
          </div>
        </div>

        <div
          className={`${style.signout} ${selected === "signout" ? style.selected : ""
            }`}
          onClick={() => {
            setLogout(true);
          }}
        >
          <RxExit />
          <p>Signout</p>
        </div>
      </div>

      {logout && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={logout}
          onClose={() => {
            setLogout(false);
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
        >
          <div className={style.Popup}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setLogout(false);
                }}
              />
            </div>
            <div className={style.popupSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to logout?</p>
              </div>
              <div className={style.PopupButton}>
                <button
                  className={style.btnYes}
                  onClick={(e) => {
                    handleLogout();
                  }}
                >
                  YES
                </button>
                <button
                  className={style.btnNo}
                  onClick={() => {
                    setLogout(false);
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {sessionExpire&&
      <Modal  aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={sessionExpire}
      onClose={() => {
        
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}>
      <div className={style.expirePopup}>
      <div className={style.expirePopupText}><p>Your session was Expired<br/>Please login again</p></div>

        <div className={style.expirePopupButton}><button onClick={()=>{
          handleLogout()
        }}>Ok</button></div>

      </div>
      </Modal>}
    </div>
  );
}

export default Sidebar;
