import React, { useState } from "react";
import style from "./AdminManage.module.css";
import { IoSearch } from "react-icons/io5";
import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Modules from "../Modules/Modules";
import FeatureManage from "../FeatureManage/FeatureManage";
import OutlinedInput from "@mui/material/OutlinedInput";
import { HiSortDescending } from "react-icons/hi";
import { FormControl, Select, MenuItem } from "@mui/material";

function AdminManage() {

  const [value, setValue] = useState("Modules");
  const [selectedHub, setSelectedHub] = useState("");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("asc");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const switchToFeatureTab = () => {
    setValue("FeatureManage");
  };

  const renderContent = () => {
    switch (value) {
      case "Modules":
        return <Modules switchToFeatureTab={switchToFeatureTab} searchQuery={search} sortOption={sort}/>;
      case "FeatureManage":
        return <FeatureManage />;
      default:
        return null;
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.Header}>
          <div className={style.HeaderText}>
            <p>Admin Management</p>
          </div>

          <div className={style.dropdownSelect}>
            <Box sx={{ minWidth: 100 }}>
              <FormControl sx={{ m: 2, minWidth: 100 }} size="small">
                <Select
                 onChange={handleSortChange}
                  value={sort}
                  displayEmpty
                  input={
                    <OutlinedInput
                      sx={{
                        "& fieldset": {
                          borderColor: "#59293a",
                          borderWidth: 2,
                        },
                        "&fieldset:hover ": {
                          borderColor: "#794a57",
                        },
                        "& fieldset:active": {
                          borderColor: "#a96b84",
                        },
                      }}
                    />
                  }
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    {" "}
                    <HiSortDescending className={style.icon} />{" "}
                    <em>Sort</em>
                  </MenuItem>

                  <MenuItem key="" value="asc">
                    <em>A-Z</em>
                  </MenuItem>

                  <MenuItem key="" value="desc">
                    <em>Z-A</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className={style.search}>
            <IoSearch className={style.searchIcon} />
            <input
              type="text"
              placeholder=""
              className={style.searchInput}
              value={search}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className={style.tabMenu}>
          <div className={style.tabItems}>
            <div className={style.tab}>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                </Tabs>
              </Box>
            </div>
          </div>

          <div className={style.renderContents}> {renderContent()}</div>
        </div>
      </div>
    </>
  );
}

export default AdminManage;
